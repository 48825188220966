import React from "react";
import LogotypeLink from "./LogotypeLink";
import i18n from "../i18n";

export default function PageHeaderView({logotypeIsDisabled, showContactUsLink}) {

  return <div className="logo-container">
    <LogotypeLink disabled={logotypeIsDisabled} />

    {showContactUsLink && <a className="contact-link" href="mailto:contact@unite.com">{i18n.t("contact_us")}</a>}
  </div>;
}