import React from "react";
import AppContext from "../contexts/AppContext";
import Loading from "../components/Loading";
import {generatePath} from "react-router";
import routes from "../routes";
import FileChooseButton from "../components/FileChooseButton";
import {SvgSprite} from "../components/SvgSprite";
import * as api from "../utils/api";
import * as collageUtils from "../utils/collage";
import {openWindow} from "../utils/window";
import ErrorModal from "../components/ErrorModal";
import i18n from "../i18n";
import {ApiResponseError} from "../utils/api";
import {apiResponseErrorCodes} from "../utils/api";
import {copyText, copyTextWithInvisibleElement, getLocationQueryObject} from "../utils/text";
import {logEvent, userEvents} from "../utils/log";
import PageHeaderView from "../components/PageHeaderView";
import {CopyToast} from "../components/CopyToast";
import {webviewShare, webviewShareDownload, webviewShareFacebook} from "../utils/webview";
import SubscribersNofityModal from "../components/SubscribersNofityModal";
import Slider from "react-slick";
import CollageSetEmailModal from "../components/CollageSetEmailModal";
import EditPage from "./EditPage";

const FETCH_INTERVAL = 1000;

function isMobileScreenSize() {
  return window.innerWidth <= 950;
}

export default class FinishPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isMobileScreen: isMobileScreenSize(),
      photo: null,
      collage: null,
      copyToastIsVisible: false,
      selectedBonus: null,
    };

    this.fetchTimer = null;
  }

  componentDidMount() {
    if ( this.props.location.state
      && this.props.location.state.photo
      && this.props.location.state.collage
    ) {
      this.setState({
        photo: this.props.location.state.photo,
        collage: this.props.location.state.collage,
        bonuses: collageUtils.mapBonuses(this.props.location.state.collage, BonusTooltipDisney3D),
        isLoading: false,
      })
    } else {
      this.props.history.replace(generatePath(routes.COLLAGE, {hash: this.props.match.params.hash}));
    }

    window.addEventListener("resize", this.handleScreenResize);
  }

  componentWillUnmount() {
    clearTimeout(this.fetchTimer);
    window.removeEventListener("resize", this.handleScreenResize);
  }

  handleScreenResize = () => {
    const isMobileScreen = isMobileScreenSize();
    if (isMobileScreen !== this.state.isMobileScreen) {
      this.setState({isMobileScreen});
    }
  };

  fetchCollage = () => {
    api.fetchCollage(this.props.match.params.hash)
      .then((res) => this.handleCollage(res.collage))
      .catch(this.handleError)
  };

  handleCollage = (collage) => {
    if (this.state.collage === null) {
      const query = getLocationQueryObject();

      logEvent(userEvents.PAGE_COLLAGE, {
        collage_id: collage.id,
        style: collage.style,
        is_owner: collage.is_owner,
        ref: query.ref || undefined,
      });
    }

    const nextState = {
      collage,
      bonuses: collageUtils.mapBonuses(collage, BonusTooltipDisney3D),
    };

    document.title = collage.caption
        ? i18n.t("collage_page__meta_title_with_caption", {caption: collage.caption})
        : i18n.t("collage_page__meta_title");

    if (collageUtils.isProcessed(collage)) {
      nextState.isLoading = false;
    } else if (collageUtils.isFailed(collage)) {
      this.handleCollageError(collage);
    } else {
      this.fetchTimer = setTimeout(this.fetchCollage, FETCH_INTERVAL);
    }

    this.setState(nextState);
  };

  handleCollageError = (collage) => {
    this.context.pushModal(ErrorModal, {
      key: "FinishPage-ErrorModal",
      buttons: ErrorModal.getDefaultReloadButton(),
    });
  };

  handleError = (err) => {
    if (window.appConfig.isDebug) {
      console.error(err);
    }

    const props = {
      key: "FinishPage-ErrorModal",
      buttons: <React.Fragment>
        <button
          className="btn btn_width-big btn_paint"
          children={i18n.t("error_modal__reload_page")}
          onClick={() => window.location.reload()} />
      </React.Fragment>,
    };

    if (err instanceof ApiResponseError) {
      switch (err.code) {
        case apiResponseErrorCodes.NOT_FOUND: {
          props.message = i18n.t("collage_page__collage_not_found");
          break;
        }
        case apiResponseErrorCodes.DELETED: {
          props.message = i18n.t("collage_page__collage_is_deleted");
          break;
        }
        default:
          break;
      }
    }

    this.context.pushModal(ErrorModal, {...props});
  };

  handleManageButtonClick = () => {
    this.props.history.push(generatePath(routes.MANAGE, {
      hash: this.state.collage.hash,
      token: this.state.collage.token
    }));
  };

  handleShareToFacebookButtonClick = () => {
    logEvent(userEvents.SHARE, {
      collage_id: this.state.collage.id,
      style: this.state.collage.style,
      provider: "facebook",
      page: "collage",
    });

    api.logCollageShare(this.state.collage.id, "facebook").catch(() => {/* ignore */});

    const shareUrl = new URL(this.state.collage.urls.share);
    shareUrl.searchParams.set("ref", "facebook");

    if (window.clientConfig.isWebview) {
      webviewShareFacebook(shareUrl.toString(), this.state.collage.file.url);
    } else {
      openWindow(
        `https://www.facebook.com/dialog/share?app_id=${window.appConfig.facebook.appId}`
          + `&display=popup&href=${encodeURIComponent(shareUrl.toString())}`,
        "Facebook Share"
      );
    }
  };

  handleShareToSnapchatButtonClick = () => {
    logEvent(userEvents.SHARE, {
      collage_id: this.state.collage.id,
      style: this.state.collage.style,
      provider: "snapchat",
      page: "collage",
    });

    api.logCollageShare(this.state.collage.id, "snapchat").catch(() => {/* ignore */});

    const shareUrl = new URL(this.state.collage.urls.share);
    shareUrl.searchParams.set("ref", "snapchat");

    if (window.clientConfig.isWebview) {
      // webviewShareSnapchatImage(shareUrl.toString(), this.state.collage.file.url);
      const sw = window.clientConfig.isWebviewIOS
        ? window.screen.width
        : Math.floor(window.screen.width * (window.devicePixelRatio || 1));

      webviewShare({
        providers: "[11]",
        caption_text: "",
        sticker_url: encodeURIComponent(this.state.collage.file.url),
        attachment_url: encodeURIComponent(shareUrl),
        sc_sticker_posx: 0.5,
        sc_sticker_posy: 0.5,
        sc_sticker_width: Math.floor(sw * 0.7),
        sc_sticker_height: Math.floor(sw * 0.7),
        sc_sticker_rotation: 0.0,
      });
    } else {
      if (this.snapchatShareButton) {
        this.snapchatShareButton.click();
      } else {
        this.snapchatShareButton = document.createElement("button");
        this.snapchatShareButton.setAttribute("data-share-url", shareUrl.toString());
        window.snap.creativekit.initalizeShareButtons([this.snapchatShareButton]);
        setTimeout(() => this.snapchatShareButton.click(), 200);
      }
    }
  };

  handleCopyLinkButtonClick = () => {
    logEvent(userEvents.SHARE, {
      collage_id: this.state.collage.id,
      style: this.state.collage.style,
      provider: "copylink",
      page: "collage",
    });

    this.handleCopyLink();
  };

  handleCopyLink = () => {
    const url = new URL(this.state.collage.urls.share);
    url.searchParams.set("ref", "link");

    const onCopySuccess = () => {
      this.setState({copyToastIsVisible: true});
      this.copyToastTimer = setTimeout(this.handleCopyToastClose, 5000);
    };

    copyText(url.toString())
      .then(onCopySuccess)
      .catch(() => copyTextWithInvisibleElement(url.toString()))
      .then(onCopySuccess);
  };

  handleCopyToastClose = () => {
    this.setState({copyToastIsVisible: false});
    clearTimeout(this.copyToastTimer);
  };

  handleDownloadButtonClick = () => {
    logEvent(userEvents.DOWNLOAD, {
      collage_id: this.state.collage.id,
      style: this.state.collage.style,
      is_owner: this.state.collage.is_owner,
      page: "collage",
    });

    if (SubscribersNofityModal.shouldBeShown(this.state.collage)) {
      this.context.pushModal(SubscribersNofityModal, {
        key: "FinishPage-SubscribersNofityModal",
        collage: this.state.collage,
      });
    }

    const isBonus = this.state.selectedBonus
      && collageUtils.isProcessed(this.state.selectedBonus);

    if (window.clientConfig.isWebview) {
      webviewShareDownload(isBonus
        ? this.state.selectedBonus.file.url
        : this.state.collage.file.url);
    } else {
      const downloadUrl = new URL(this.state.collage.urls.download);

      if (isBonus) {
        downloadUrl.searchParams.set("bonus_id", this.state.selectedBonus.id);
      }

      window.location.href = downloadUrl.toString();
    }
  };

  handleAttachFileSelected = (file) => {
    this.props.history.push(routes.ATTACH, {file, collage: this.state.collage});
  };

  handleCreateNewCollageFileSelected = (file) => {
    this.props.history.push(routes.CREATE, {file});
  };

  handleInviteFriendsButtonClick = () => {
    if (this.state.collage.is_owner_email_attached) {
      this.handleCopyLink();
      return;
    }

    logEvent(userEvents.INVITE_PEOPLE_MODAL_OPEN, {
      collage_id: this.state.collage.id,
      style: this.state.collage.style,
    });

    this.context.pushModal(CollageSetEmailModal, {
      key: "FinishPage_CollageSetEmailModal",
      collage: this.state.collage,
      onSuccess: () => this.handleCopyLink(),
      onDismissed: () => this.setState({collage: this.state.collage}),
    });
  };

  handleChangeCreativeStyleButtonClick = (type) => {
    this.props.history.push({
      pathname: routes.EDIT,
      state: {
        collage: this.state.collage,
        photoId: this.state.photo.id,
        type,
      }
    });
  };

  renderActionsButtonsForOwner = () => {
    return <div className="result-page__action-btns">
      <div>
        <InvitePeopleButton
          hidden={!isMobileScreenSize()}
          collage={this.state.collage}
          onClick={this.handleInviteFriendsButtonClick} />

        <button
          hidden={!this.state.collage.is_owner_email_attached}
          className="result-page__moderate-btn btn btn_small btn_transparent"
          children={i18n.t("collage_page__moderate_button")}
          onClick={this.handleManageButtonClick} />

        <button
          hidden={this.state.collage.style === collageUtils.styles.cartoon}
          className="result-page__moderate-btn btn btn_small btn_transparent"
          children={i18n.t("finish_page__change_head_button")}
          onClick={() => this.handleChangeCreativeStyleButtonClick(EditPage.TYPE_HEAD)} />

        <button
          hidden={!collageUtils.isBodyStyle(this.state.collage.style)}
          className="result-page__moderate-btn btn btn_small btn_transparent"
          children={i18n.t("finish_page__change_body_button")}
          onClick={() => this.handleChangeCreativeStyleButtonClick(EditPage.TYPE_BODY)} />

        <FileChooseButton
          className="result-page__action-btn btn btn_small btn_text"
          place="CollagePage_NewCollage_Owner"
          isOwner={this.state.collage.is_owner}
          onFileSelected={this.handleCreateNewCollageFileSelected}>
          <SvgSprite className="result-page__action-btn-icon" viewBox="0 0 16 16" icon="icon-reload" />
          {i18n.t("collage_page__new_collage_button")}
        </FileChooseButton>
      </div>
    </div>;
  };

  renderLeftColumnForOwner = () => {
    const text1 = i18n.t("finish_page__text_1");
    const text2 = i18n.t("finish_page__text_2");

    return <div className="result-page__column result-page__column_1">
      <h2 className="title-h2 result-page__title" dangerouslySetInnerHTML={{__html: text1}} />
      <p className="result-page__text" dangerouslySetInnerHTML={{__html: text2}} />

      <div className="share-container"> {/*hidden={this.state.collage.is_owner_email_attached}*/}
        <InvitePeopleButton
          hidden={isMobileScreenSize()}
          collage={this.state.collage}
          onClick={this.handleInviteFriendsButtonClick} />
      </div>

      <div className="share-container" hidden={true}>
        <h4 className="share-container__title" dangerouslySetInnerHTML={{__html: i18n.t("collage_page__share_save__label")}} />
        <button
          className="result-page__action-btn btn btn_small btn_text share-container__btn-download"
          onClick={this.handleDownloadButtonClick}>
          <SvgSprite className="result-page__action-btn-icon" viewBox="0 0 16 16" icon="icon-download" />
        </button>
        <button
          hidden={!this.context.installedApps.facebook}
          className="share-container__btn"
          onClick={this.handleShareToFacebookButtonClick}>
          <SvgSprite className="share-container__btn-icon" viewBox="0 0 80 80" icon="icon-fb" />
        </button>
        <button
          hidden={window.clientConfig.isWeb || !this.context.installedApps.snapchat}
          className="share-container__btn"
          onClick={this.handleShareToSnapchatButtonClick}>
          <SvgSprite className="share-container__btn-icon" viewBox="0 0 80 80" icon="icon-snapchat" />
        </button>
        <button
          className="share-container__btn"
          onClick={this.handleCopyLinkButtonClick}>
          <SvgSprite className="share-container__btn-icon" viewBox="0 0 80 80" icon="icon-copy" />
        </button>
        {/* <button
          className="share-container__btn"
          onClick={this.handleDownloadButtonClick}>
          <SvgSprite className="share-container__btn-icon" viewBox="0 0 80 80" icon="icon-download-big" />
        </button> */}
      </div>

      <p hidden={!this.state.collage.is_owner_email_attached}
         className="result-page__text owner-desktop"
         dangerouslySetInnerHTML={{__html: i18n.t("collage_page__owner_text_3")}} />
    </div>;
  };

  renderRightColumnForOwner = () => {
    // селеб показываем только для гостей если есть превью файл (превью всегда с селебами)
    // и только для автора когда он один на фото (т.е. file.id == preview_file.id)
    const celebsIsVisible = collageUtils.withCelebsStyle(this.state.collage.style)
      && this.state.collage.is_owner
      && this.state.collage.preview_file
      && this.state.collage.preview_file.id === this.state.collage.file.id;

    const collage = {
      file: this.state.collage.caption_file || this.state.collage.file,
      style: this.state.collage.style,
      status: this.state.collage.status,
    };

    const sliderSettings = {
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      afterChange: (index) => this.setState({
        selectedBonus: this.state.bonuses[index - 1] || null,
      }),
      responsive: [
        {
          breakpoint: 950,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    };

    return <React.Fragment>
      <div className="result-page__column result-page__column_2">
        <div>
          {/*<div className="result-page__collage-title-container" hidden={!celebsIsVisible}>*/}
            {/*<p className="result-page__collage-subtitle" dangerouslySetInnerHTML={{__html: i18n.t("result_page__collage_subtitle")}} />*/}
          {/*</div>*/}
          <div className="manage-page__content">
            <div className="manage-page__collage-block">
              <Slider {...sliderSettings} className="manage-page__collage-block-processing manage-page__slider">
                <SlideView key={"primary_collage"} data={collage} isDemo={celebsIsVisible} />
                {this.state.bonuses.map((bonus) => <SlideView key={`bonus_${bonus.style}`} data={bonus} />)}
                {(window.screen.width > 600) ? <div className="manage-page__slide"><div className="empty-slide" /></div> : null}
              </Slider>
            </div>
          </div>

          {this.renderActionsButtonsForOwner()}
        </div>
      </div>
      <p className="result-page__text owner-mob"
         hidden={!this.state.collage.is_owner_email_attached}
         dangerouslySetInnerHTML={{__html: i18n.t("collage_page__owner_text_3")}} />
    </React.Fragment>;
  };

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    return <main className="main-section result-page">
      <div className="container">
        <PageHeaderView />

        <div className="result-page__content owner">
          <React.Fragment>
            {this.renderLeftColumnForOwner()}
            {this.renderRightColumnForOwner()}
          </React.Fragment>
        </div>
      </div>

      {this.state.copyToastIsVisible && <CopyToast onCloseButtonClick={this.handleCopyToastClose} />}
    </main>;
  }
}

FinishPage.contextType = AppContext;

class SlideView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTooltip: true,
    };
  }

  handleCloseClick = (e) => {
    e.preventDefault();

    this.setState({
      showTooltip: false,
    });
  };

  render() {
    const Tooltip = this.state.showTooltip && this.props.data.tooltip;
    const isProcessed = collageUtils.isProcessed(this.props.data) || this.props.data.status === null;
    const isProcessing = collageUtils.isProcessing(this.props.data);

    return <div className="manage-page__slide">
      {isProcessed && <React.Fragment>
        <img src={this.props.data.file.url} alt="Collage" />
        {this.props.isDemo && <p className="slide-caption-text" dangerouslySetInnerHTML={{__html: i18n.t("result_page__collage_subtitle")}} />}
      </React.Fragment>}
      {isProcessing && <Loading className="loader__processing"/>}
      {this.props.data.text && <p className="slide-caption" dangerouslySetInnerHTML={{__html: this.props.data.text}} />}
      {Tooltip && <Tooltip onCloseClick={this.handleCloseClick} />}
    </div>
  }
}

function BonusTooltipDisney3D(props) {
  return <div className="slide-tooltip">
    <SvgSprite className="slide-tooltip-icon" viewBox="0 0 56 56" icon="icon-slide-tooltip" />
    <div>
      <h3 dangerouslySetInnerHTML={{__html: i18n.t("manage_page__bonus_tooltip_title_disney_3d")}} />
      <p dangerouslySetInnerHTML={{__html: i18n.t("manage_page__bonus_tooltip_text_disney_3d")}} />
    </div>
    <a href="#close" onClick={props.onCloseClick}><SvgSprite className="slide-tooltip-close" viewBox="0 0 12 12" icon="icon-close-tooltip" /></a>
  </div>;
}

function InvitePeopleButton({collage, onClick, hidden}) {
  const buttonText = collage.is_owner_email_attached
    ? i18n.t("collage_page__copy_invitation_link_button")
    : ([1, 2, 3, 6, 7].includes(window.clientConfig.splitsGroupId)
      ? i18n.t("collage_page__add_friends_button")
      : i18n.t("collage_page__invite_friends_button"));

  return <button
    hidden={hidden}
    className="btn btn_paint"
    children={buttonText}
    onClick={onClick} />
}