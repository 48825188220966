import React, {Component} from 'react';
import AppContext from "../contexts/AppContext";

export default class Modal extends Component {

  constructor(props) {
    super(props);

    this.overlayRef = React.createRef();

    this.dismiss = this.dismiss.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.onDismissed = this.onDismissed.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.handleOverlayClick = this.handleOverlayClick.bind(this);
    this.handleCloseButtonClick = this.handleCloseButtonClick.bind(this);
  }

  onDismiss(trigger) {
    return true;
  }

  onDismissed(trigger) {
    this.props.onDismissed && this.props.onDismissed(this.state, trigger);
  }

  dismiss(trigger) {
    if (this.onDismiss(trigger) === false) {
      return;
    }

    this.context.popModal(() => this.onDismissed(trigger));
  }

  handleOverlayClick(e) {
    if (e.target === this.overlayRef.current) {
      if (this.props.canBeCloseByOverlay) {
        this.dismiss("overlay");
      }
    }
  }

  handleCloseButtonClick(e) {
    this.dismiss("button");
  }

  renderModal() {
    return <React.Fragment />;
  }

  render() {
    return <div
      ref={this.overlayRef}
      className={`modal ${this.props.className || ""} ${this.className || ""}`}
      onClick={this.handleOverlayClick}>

      {/* <button
        hidden={!this.props.canBeCloseByButton}
        className="modal-close-button"
        onClick={this.handleCloseButtonClick}>
        <SvgSprite viewBox="0 0 20 20" icon="icon-close" />
      </button> */}

      <div className="modal__container" children={this.renderModal()} />
    </div>;
  }
}

Modal.contextType = AppContext;