module.exports = {
  "strings": {
    "logotype": "unite.com",
    "try_another_photo": "Попробуйте с другим фото",
    "cancel": "Отменить",

    "btn_retry": "Попробовать снова",
    "btn_download": "Загрузить",
    "btn_start_my_own_collage": "Начать сейчас",

    "email__error_empty": "Пожалуйста, укажите email, чтобы получать уведомления о статусе вашего портрета",
    "email__error_invalid_format": "Ой! Кажется, вы указали некорректный email. Попробуйте еще раз.",

    "index_page__greeting": "Создайте уникальный групповой портрет!",
    "index_page__text": "Зовите всех и погружайтесь в увлекательный процесс создания виртуального группового селфи. Загрузите своё фото и поделитесь ссылкой, чтобы другие люди смогли стать частью вашего портрета.",
    "index_page__select_photo_button": "Загрузите фото, чтобы начать",

    "create_page__form_title_step_1": "Настройка портрета",
    "create_page__form_title_step_2": "Добавьте email",
    "create_page__form_message_step_1": "Выберите стиль портрета и решите, как мы будем добавлять участников: после вашего подтверждения или сразу после добавления фото.",
    "create_page__form_message_step_2": "Пожалуйста, укажите email, чтобы получать уведомления о новых участниках вашего портрета",
    "create_page__form_moderation_type": "Как будут добавляться участники:",
    "create_page__form_moderation_type_pre": "После вашего подтверждения",
    "create_page__form_moderation_type_pre_tip": "участник появится на портрете только после вашего подтверждения",
    "create_page__form_moderation_type_post": "Сразу после добавления фото участником",
    "create_page__form_moderation_type_post_tip": "участник появится на портрете сразу после добавления фото, но вы всегда можете управлять списком участников",
    "create_page__form_photo": "Ваше фото",
    "create_page__form_email": "Добавить email:",
    "create_page__form_email__tip_title": "Зачем добавлять email?",
    "create_page__form_email__tip_text": "На ваш email будут приходить уведомления об изменениях статуса вашего портрета. Мы не любим СПАМ и обещаем не присылать никаких маркетинговых писем. И, безусловно, ваш адрес не будет передан третьим лицам.",
    "create_page__form_photos_target": "Максимальное число участников",
    "create_page__form_collage_type": "Выберите стиль портрета",
    "create_page__form_next": "Далее",
    "create_page__form_submit": "Готово",
    "create_page__select_variant_title": "Осталось совсем немного!<br />Выберите понравившийся результат",
    "create_page__confirm_variant_title": "Мы готовы добавить ваше фото!",
    "create_page__confirm_variant_subtitle": "Нажмите \"Далее\", если вам нравится результат, или выберите другое фото",
    "create_page__confirm_variant__continue_button": "Далее",
    "btn_more": "больше",
    "btn_less": "меньше",
    "btn_preview": "предпросмотр",

    "choose_body_title": "Чтобы продолжить, выберите понравившийся стиль?",

    "image_warning_title": "Хотите выглядеть еще круче?",
    "image_warning_text": "Для лучшего результата <span>выберите или сделайте лицевое фото</span>",

    "attach_page__select_variant_title": "Почти готово!<br />Чтобы продолжить, выберите понравившийся результат",
    "attach_page__confirm_variant_title": "Мы готовы добавить ваш результат!",
    "attach_page__confirm_variant__continue_button": "Далее",
    "attach_page__preview_collage__title": "Мы готовы добавить ваш результат!",
    "attach_page__preview_collage__subtitle": "Нажмите \"Далее\", если вам нравится результат, или выберите другое фото",
    "attach_page__preview_collage__continue_button": "Далее",
    "attach_page__result_text1_post": "Ваше фото добавлено. Осталось дождаться других участников.",
    "attach_page__result_text1_pre": "Мы добавим вас после подтверждения автора портрета",
    "attach_page__create_new_collage_button": "Создайте свой групповой портрет",
    "attach_page__result_text2_post": "Ура!",
    "attach_page__result_text2_pre": "Ура!",
    "attach_page__result_form_text": "Укажите ваш email, чтобы получать обновления статуса портрета и приятные бонусы.",
    "attach_page__subscribe_form_submitted_text": "Спасибо! Мы будем держать вас в курсе 🙂",

    "collage_page__owner_text_3": "PS. не забудьте проверить почту, чтобы получить ссылку на редактирование вашего группового портрета",
    "collage_page__guest_title_1_finalized": "Кажется, этот портрет уже завершен",
    "collage_page__guest_subtitle_1_finalized": "Это знак! Вы можете создать свой собственный групповой портрет прямо сейчас",
    "collage_page__guest_title_1": "Добро пожаловать!",
    "collage_page__guest_subtitle_1": "Чтобы присоединиться к портрету, вам нужно просто загрузить свое фото ниже",
    "collage_page__guest_title_2": "Не понимаете, что к чему? Мы сейчас расскажем",
    "collage_page__guest_subtitle_2": "Unite.com - интерактивный сервис для создания виртуальных групповых портретов, который помогает собрать вместе друзей, подписчиков, коллег - кого угодно! Вы можете добавить себя на существующий портрет или создать свой собственный по ссылке ниже",
    "collage_page__share_save__label": "Сохранить и поделиться:",
    "collage_page__new_collage_button": "Создать свой портрет",
    "collage_page__back_button": "Назад",
    "collage_page__moderate_button": "Редактировать этот портрет",
    "collage_page__moderate_text": "Как создатель этого портрета вы можете переключиться в режим его редактирования <a href=\"#\">здесь</a>",
    "collage_page__collage_not_found": "Групповой портрет не найден",
    "collage_page__collage_is_deleted": "Групповой портрет удален",
    "collage_page__attach_guest_photo_button": "Загрузить фото",
    "collage_page__create_new_collage_button": "Создать свой портрет",
    "collage_page__invite_friends_button": "Пригласить участников",
    "collage_page__add_friends_button": "Добавить участников",
    "collage_page__copy_invitation_link_button": "Скопировать ссылку на приглашение",
    "collage_page__meta_title": "Присоединяйтесь к моему портрету",
    "collage_page__meta_title_with_caption": "Присоединяйтесь к портрету «{{ caption }}»",

    "collage_set_email_modal__title": "Еще один шаг",
    "collage_set_email_modal__message": "Пожалуйста, укажите email, чтобы получить доступ к редактированию портрета и получать обновления его статуса",
    "collage_set_email_modal__moderation_mode": "Режим редактирования",
    "collage_set_email_modal__cancel_button": "Не сейчас",
    "collage_set_email_modal__send_button": "Получить ссылку на приглашение",

    "collage_download_page__title": "Рады видеть вас снова!",
    "collage_download_page__text": "Групповой портрет, к которому вы присоединялись, готов! Получите его сейчас и задумайтесь о создании своего собственного. 🙃",

    "result_page__collage_subtitle": "Просто пример завершённого портрета",

    "caption_form_title": "Добавить подпись",
    "caption_form_button": "сохранить",
    "caption_form_skip": "пропустить",

    "manage_page__title_finalized": "Ваш портрет завершен!",
    "manage_page__text_finalized": "Портрет недостаточно хорош? Вы всегда можете изменить число участников.",
    "manage_page__title": "Редактировать портрет",
    "manage_page__text": "Чтобы завершить виртуальный портрет, подтвердите участников или отредактируйте их список",
    "manage_page__list_pending": "Ожидают подтверждения",
    "manage_page__list_attached": "Добавлены",
    "manage_page__list_approved": "Подтверждены",
    "manage_page__list_detached": "Удалены",
    "manage_page__apply_button": "Применить",
    "manage_page__finalize_modal__title": "Настройки вашего портрета",
    "manage_page__finalize_modal__text": "Завершаем портрет знаменитостями или ждём друзей? Давайте определимся",
    "manage_page__bonus_disney_3d_0": "<span>ещё {{ count }} участник,</span> <span>чтобы открыть Disney-like</span>",
    "manage_page__bonus_disney_3d_1": "<span>ещё {{ count }} участника,</span> <span>чтобы открыть Disney-like</span>",
    "manage_page__bonus_disney_3d_2": "<span>ещё {{ count }} участников,</span> <span>чтобы открыть Disney-like</span>",
    "manage_page__bonus_caricature_0": "<span>ещё {{ count }} участник,</span> <span>чтобы открыть Caricature</span>",
    "manage_page__bonus_caricature_1": "<span>ещё {{ count }} участника,</span> <span>чтобы открыть Caricature</span>",
    "manage_page__bonus_caricature_2": "<span>ещё {{ count }} участников,</span> <span>чтобы открыть Caricature</span>",
    "manage_page__bonus_tooltip_title_disney_3d": "Любите бонусы?",
    "manage_page__bonus_tooltip_text_disney_3d": "Открывайте новые стили, приглашая участников",
    "manage_page__change_caption_button": "Изменить подпись",
    "manage_page__set_caption_button": "Добавить подпись",
    "manage_page__caption_placeholder": "Введите текст...",

    "finish_page__text_1": "Круто! Давайте пригласим участников.",
    "finish_page__text_2": "Чтобы завершить портрет, нужно больше участников. Поделитесь с людьми уникальной ссылкой на приглашение через социальные сети (Facebook, Snapchat и др.)",
    "finish_page__change_head_button": "Изменить стиль обработки головы",
    "finish_page__change_body_button": "Изменить стиль обработки тела",

    "snapchat_page__text": "Скопируйте ссылку и откройте её в браузере",

    "creative_error_modal__title": "Что-то пошло не так",

    "copy_toast__title": "Ссылка скопирована",
    "copy_toast__text": "Ссылка на эту страницу скопирована в буфер обмена!",

    "error_modal__default_title": "Ой, что-то пошло не так.",
    "error_modal__default_message": "Что-то пошло не так. Перезагрузите страницу или попробуйте чуть позже.",
    "error_modal__network_title": "Проблема с Интернет-соединением",
    "error_modal__network_message": "Пожалуйста, проверьте подключение к сети Интернет",

    "subscribers_nofity_modal__title": "Какой крутой виртуальный портрет! 🔥",
    "subscribers_nofity_modal__message": "Хотите поделиться портретом с участниками? Мы можем уведомить их по email. 😉",
    "subscribers_nofity_modal__btn_send": "Поделиться",
    "subscribers_nofity_modal__btn_cancel": "Не сейчас",

    "error__api_code_1": "Внутренняя ошибка",
    "error__api_code_2": "Некорректные параметры",
    "error__api_code_3": "Что-то пошло не так",
    "error__api_code_401": "Недостаточно прав доступа",
    "error__api_code_404": "Не найдено",
    "error__api_code_410": "Запрашиваемый контент удален",
    "error__api_code_415": "Некорректный формат выбранного файла",

    "processing_text_1": "Работаем над вашим портретом... 🖼",
    "processing_text_2": "Виртуальная тусовка безопасна. Мы знаем 🥳",
    "processing_text_3": "Готовы поспорить, что портрет получится нереально крутым 😎",
    "processing_text_4": "Лучшие портреты достаются тем, кто умеет ждать ⏳",

    "contact_us": "Контакты",
    "title": "Создайте свой групповой портрет",
    "description": "Зовите всех и погружайтесь в увлекательный процесс создания виртуального группового селфи. Загрузите своё фото и поделитесь ссылкой, чтобы другие люди смогли стать частью вашего портрета.",
  },
};
