import React from "react";
import Modal from "./Modal";
import i18n from "../i18n";
import * as api from "../utils/api";
import {emailIsValid} from "../utils/text";
import ErrorModal from "./ErrorModal";
import {logEvent, userEvents} from "../utils/log";
import {SvgSprite} from "../components/SvgSprite";
import ClientStorage from "../utils/client-storage";

const MODERATION_TYPE_PRE = "pre";
const MODERATION_TYPE_POST = "post";

export default class CollageSetEmailModal extends Modal {

  state = {
    form: {
      email: {
        value: ClientStorage.getUserEmail() || "",
        error: undefined,
      },
      moderationType: {
        value: MODERATION_TYPE_POST,
        error: undefined,
      }
    },
  };

  handleModerationTypeFormFieldChange = (e) => {
    this.setFormFieldState("moderationType", e.target.value);
  };

  handleEmailFormFieldChange = (e) => {
    this.setFormFieldState("email", e.target.value);
  };

  setFormFieldState = (field, value, error) => {
    this.setState({form: {...this.state.form, [field]: {value, error}}});
  };

  setFormFieldError = (field, error) => {
    this.setState({form: {...this.state.form, [field]: {...this.state.form[field], error}}});
  };

  handleFormEmailSubmit = (e) => {
    e.preventDefault();

    if (this.state.form.email.value.length === 0) {
      this.setFormFieldError("email", i18n.t("email__error_empty"));
      return;
    }

    if (!emailIsValid(this.state.form.email.value)) {
      this.setFormFieldError("email", i18n.t("email__error_invalid_format"));
      return;
    }

    ClientStorage.setUserEmail(this.state.form.email.value);

    const params = {
      email: this.state.form.email.value,
      is_premoderation: this.state.form.moderationType.value === MODERATION_TYPE_PRE ? 1 : 0,
    };

    api.collageOwnerAttachEmail(this.props.collage.hash, params)
      .then((res) => {
        logEvent(userEvents.INVITE_PEOPLE_MODAL_SUBMITTED, {
          collage_id: this.props.collage.id,
          style: this.props.collage.style,
        });

        this.props.collage.is_owner_email_attached = true;
        this.props.onSuccess && this.props.onSuccess(res);
        this.dismiss();
      })
      .catch((err) => {
        this.context.pushModal(ErrorModal, {
          key: `${this.props.key}-ErrorModal`,
          error: err,
        });
      })
  };

  handleCancelButtonClick = () => {
    logEvent(userEvents.INVITE_PEOPLE_MODAL_CANCELED, {
      collage_id: this.props.collage.id,
      style: this.props.collage.style,
    });

    this.dismiss();
  };

  renderModal() {
    return <div className="modal__content modal__set-email">
      <div className="modal__content-container">
        <h2 className="title-h2 modal__title" dangerouslySetInnerHTML={{__html: i18n.t("collage_set_email_modal__title")}} />
        <p className="modal__text" dangerouslySetInnerHTML={{__html: i18n.t("collage_set_email_modal__message")}} />

        <form className="form" onSubmit={this.handleFormEmailSubmit} noValidate id="set-email-form">
          <div className="form__input-container">
            <input
              autoFocus={true}
              type="email"
              className="form__input form__email"
              value={this.state.form.email.value}
              onChange={this.handleEmailFormFieldChange} />
            <span
              hidden={!this.state.form.email.error}
              className="form__email-error-message"
              dangerouslySetInnerHTML={{__html: this.state.form.email.error}} />
          </div>

          <p className="modal__types-title" dangerouslySetInnerHTML={{__html: i18n.t("collage_set_email_modal__moderation_mode")}} />

          <div className="form__type-block">
            <label htmlFor="form-type2" className="form__label">
              <input
                type="radio"
                className="form__radio"
                id="form-type2"
                name="form-type-check"
                value={MODERATION_TYPE_POST}
                readOnly
                checked={this.state.form.moderationType.value === MODERATION_TYPE_POST}
                onClick={this.handleModerationTypeFormFieldChange} />
              <span
                className="form__label-name"
                dangerouslySetInnerHTML={{__html: i18n.t("create_page__form_moderation_type_post")}} />
              <span
                className="form__label-subname"
                dangerouslySetInnerHTML={{__html: i18n.t("create_page__form_moderation_type_post_tip")}} />
            </label>
            <label htmlFor="form-type1" className="form__label">
              <input
                type="radio"
                className="form__radio"
                id="form-type1"
                name="form-type-check"
                value={MODERATION_TYPE_PRE}
                readOnly
                checked={this.state.form.moderationType.value === MODERATION_TYPE_PRE}
                onClick={this.handleModerationTypeFormFieldChange} />
              <span
                className="form__label-name"
                dangerouslySetInnerHTML={{__html: i18n.t("create_page__form_moderation_type_pre")}} />
              <span
                className="form__label-subname"
                dangerouslySetInnerHTML={{__html: i18n.t("create_page__form_moderation_type_pre_tip")}} />
            </label>
          </div>
        </form>
      </div>

      <div className="modal__buttons_container">
        {/* <button
          className="btn btn_width-big btn_transparent mr-2"
          children={i18n.t("collage_set_email_modal__cancel_button")}
          onClick={this.handleCancelButtonClick} /> */}
        <button
          type="submit"
          form="set-email-form"
          className="btn btn_width-big btn_paint"
          children={i18n.t("collage_set_email_modal__send_button")}
          onClick={this.handleFormEmailSubmit} />
      </div>

      <button
        className="btn-close"
        onClick={this.handleCancelButtonClick}>
        <SvgSprite viewBox="0 0 12 12" icon="icon-close-tooltip" />
      </button>
    </div>
  }
}