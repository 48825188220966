import React from "react";

const spriteUrl = "/assets/img/sprite.svg?" + process.env.REACT_APP_ASSETS_VERSION;

export function SvgSprite({className, icon, viewBox = "0 0 16 16", hidden}) {
  if (hidden) {
    return <React.Fragment />;
  }

  return <svg className={className} viewBox={viewBox}>
    <use xlinkHref={`${spriteUrl}#${icon}`} />
  </svg>;
}
