import React from "react";
import i18n from "../i18n";
import AppContext from "../contexts/AppContext";
import {SvgSprite} from "./SvgSprite";

const LOADING_TEXT_INTERVAL = 3000;

const textsDefault = [
  i18n.t("processing_text_1"),
  i18n.t("processing_text_2"),
  i18n.t("processing_text_3"),
  i18n.t("processing_text_4"),
];

export default class Loading extends React.Component {

  constructor(props) {
    super(props);

    this.texts = textsDefault;

    this.state = {
      textIndex: Math.floor(Math.random() * (this.texts.length - 1)),
    };

    this.updateTextIndexTimer = null;
  }

  componentDidMount() {
    this.updateTextIndexTimer = setInterval(this.updateTextIndex, LOADING_TEXT_INTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this.updateTextIndexTimer);
  }

  updateTextIndex = () => {
    if (this.state.textIndex === this.texts.length - 1) {
      this.setState({textIndex: 0});
    } else {
      this.setState({textIndex: this.state.textIndex + 1});
    }
  };

  render() {
    // у нас модалки блюрят фон и если в фоне анимация лоадера то браузер начинает сильно просидать
    // поэтому скрываем анимацию, если есть хотя бы одна модалка
    const hideAnimation = this.context.modals.length > 0 && !this.props.evenWhenModal;

    const textString = this.props.text || this.texts[this.state.textIndex];

    return <section className={"loader " + this.props.className}>
      <div className="loader__blur" hidden={hideAnimation}>
        <div className="loader__dots">
          <div className={"loader__dot"} />
          <div className={"loader__dot"} />
          <div className={"loader__dot"} />
          <div className={"loader__dot"} />
          <div className={"loader__dot"} />
          <svg>
            <defs>
              <filter id="goo">
                <feGaussianBlur in="SourceGraphic" stdDeviation="12" result="blur" />
                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
                <feBlend in="SourceGraphic" in2="goo" />
              </filter>
            </defs>
          </svg>
        </div>
      </div>
      {!this.props.withoutText && <p className="loader__text" dangerouslySetInnerHTML={{__html: textString}} />}
    </section>;
  }
}

Loading.contextType = AppContext;