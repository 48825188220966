module.exports = {
  "strings": {
    "logotype": "unite.com",
    "try_another_photo": "Try another photo",
    "cancel": "Cancel",

    "btn_retry": "Retry",
    "btn_download": "Download",
    "btn_start_my_own_collage": "Start my own group selfie",

    "email__error_empty": "Please enter email to get notified on your group selfie changes.",
    "email__error_invalid_format": "Oops! Seems you've entered an invalid email address. Please try again.",

    "index_page__greeting": "Get your group portrait started!",
    "index_page__text": "Engage anyone in cool online group selfie creation. Begin by adding your portrait and share an invitation link to ask others to join. One by one we will unite single-person selfies into a group portrait.",
    "index_page__select_photo_button": "Upload a photo to start",

    "create_page__form_title_step_1": "Set up your group selfie.",
    "create_page__form_title_step_2": "Add email",
    "create_page__form_message_step_1": "Choose the selfie style and define how we add participants: after your approval or once someone uploads their photo.",
    "create_page__form_message_step_2": "Enter your email address below so that we could let you know when someone joins your group selfie.",
    "create_page__form_moderation_type": "How we add participants:",
    "create_page__form_moderation_type_pre": "After your approval",
    "create_page__form_moderation_type_pre_tip": "people will be added after your confirmation",
    "create_page__form_moderation_type_post": "Once a participant uploads a photo",
    "create_page__form_moderation_type_post_tip": "people will be added automatically upon uploading their selfie, but you can always manage the list",
    "create_page__form_photo": "Your photo",
    "create_page__form_email": "Add email:",
    "create_page__form_email__tip_title": "Why adding email?",
    "create_page__form_email__tip_text": "We will use it only to send you the notifications about your group selfie status. We hate SPAM and promise to keep your inbox free from any marketing messages. And of course, your email address won’t be shared with anyone.",
    "create_page__form_photos_target": "Max number of people",
    "create_page__form_collage_type": "Choose a preferable style",
    "create_page__form_next": "Next",
    "create_page__form_submit": "Done",
    "create_page__select_variant_title": "You’re almost there!<br />Tap the result you like best.",
    "create_page__confirm_variant_title": "Your portrait is ready to be added!",
    "create_page__confirm_variant_subtitle": "Tap ‘Next’ if you like the result or try with another photo.",
    "create_page__confirm_variant__continue_button": "Next",
    "btn_more": "more",
    "btn_less": "less",
    "btn_preview": "preview",

    "choose_body_title": "Please choose the result you like most to proceed",

    "image_warning_title": "Wanna look even cooler?",
    "image_warning_text": "For better result please choose or take <span>a closer headshot</span>",

    "attach_page__select_variant_title": "You’re almost there!<br />Tap the result you like best to proceed.",
    "attach_page__confirm_variant_title": "Your art is ready to be added!",
    "attach_page__confirm_variant__continue_button": "Next",
    "attach_page__preview_collage__title": "Your portrait is ready to be added!",
    "attach_page__preview_collage__subtitle": "Tap ‘Next’ if you like the result or try with another photo",
    "attach_page__preview_collage__continue_button": "Next",
    "attach_page__result_text1_post": "Your photo is added. Sit tight and wait for more people to join.",
    "attach_page__result_text1_pre": "Your photo will be added once the owner approves it.",
    "attach_page__create_new_collage_button": "Start your group selfie",
    "attach_page__result_text2_post": "Cheers!",
    "attach_page__result_text2_pre": "Cheers!",
    "attach_page__result_form_text": "Add your email to get notified about the selfie status and lucky bonuses.",
    "attach_page__subscribe_form_submitted_text": "Thanks! We'll keep you posted 🙂",

    "collage_page__owner_text_3": "P.S. don’t forget to check your email for the admin link so that you could manage your group selfie at any moment.",
    "collage_page__guest_title_1_finalized": "Oh my, this portrait is already complete.",
    "collage_page__guest_subtitle_1_finalized": "It's a sign! Don't you feel like starting your own portrait now?",
    "collage_page__guest_title_1": "Hi and welcome!",
    "collage_page__guest_subtitle_1": "Join this online group selfie by adding your portrait photo below.",
    "collage_page__guest_title_2": "Feeling a bit lost here? Let us shed some light.",
    "collage_page__guest_subtitle_2": "This is an online group selfie maker that helps people to get together with their friends, followers, and whomever they want. You can join the current group selfie by uploading your photo above or start your own epic selfie by hitting the button below.",
    "collage_page__share_save__label": "Save & share:",
    "collage_page__new_collage_button": "Start new group selfie",
    "collage_page__back_button": "Back",
    "collage_page__moderate_button": "Manage this group selfie",
    "collage_page__moderate_text": "As the creator of this selfie you can switch to the moderation mode <a href=\"#\">here</a>.",
    "collage_page__collage_not_found": "Group selfie not found",
    "collage_page__collage_is_deleted": "The portrait is deleted",
    "collage_page__attach_guest_photo_button": "Upload your photo",
    "collage_page__create_new_collage_button": "Start your group selfie",
    "collage_page__invite_friends_button": "Invite people",
    "collage_page__add_friends_button": "Add people",
    "collage_page__copy_invitation_link_button": "Copy invitation link",
    "collage_page__meta_title": "Join my group selfie",
    "collage_page__meta_title_with_caption": "Join «{{ caption }}» group selfie",

    "collage_set_email_modal__title": "One final step",
    "collage_set_email_modal__message": "Please, enter your email to be able to manage participants and get status updates",
    "collage_set_email_modal__moderation_mode": "Moderation mode",
    "collage_set_email_modal__cancel_button": "Not now",
    "collage_set_email_modal__send_button": "Get invitation link",

    "collage_download_page__title": "Welcome back!",
    "collage_download_page__text": "The online group selfie you joined is ready to be downloaded. Get it now and think of getting your own started. 🙃",

    "result_page__collage_subtitle": "This is just a demo of how it's gonna look like",

    "caption_form_title": "Add caption",
    "caption_form_button": "save",
    "caption_form_skip": "skip",

    "manage_page__title_finalized": "Your portrait is complete!",
    "manage_page__text_finalized": "Not sure it's as good as it could get? You can adjust the number of participants anytime.",
    "manage_page__title": "Manage your group selfie",
    "manage_page__text": "Approve or remove people to complete this online group selfie.",
    "manage_page__list_pending": "Pending approval",
    "manage_page__list_attached": "Added",
    "manage_page__list_approved": "Approved",
    "manage_page__list_detached": "Removed",
    "manage_page__apply_button": "Apply",
    "manage_page__finalize_modal__title": "Your portrait options",
    "manage_page__finalize_modal__text": "Complete with celebs or friends only? Choose how you want it.",
    "manage_page__bonus_disney_3d": "<span>{{ count }} more people to</span> <span>open Disney-like</span>",
    "manage_page__bonus_caricature": "<span>{{ count }} more people to</span> <span>open Caricature</span>",
    "manage_page__bonus_tooltip_title_disney_3d": "Fancy bonuses?",
    "manage_page__bonus_tooltip_text_disney_3d": "Get extra styles for the number of people joined",
    "manage_page__change_caption_button": "Change caption",
    "manage_page__set_caption_button": "Set caption",
    "manage_page__caption_placeholder": "Enter caption...",

    "finish_page__text_1": "Good job! Time to ask others to join!",
    "finish_page__text_2": "To complete your portrait ask other people to join too. Just share your unique invitation link on Facebook, Snapchat, and other socials.",
    "finish_page__change_head_button": "Change head style",
    "finish_page__change_body_button": "Change body style",

    "snapchat_page__text": "Copy this link and open it in your browser",

    "creative_error_modal__title": "Processing failure",

    "copy_toast__title": "Link copied",
    "copy_toast__text": "A link to this page has been copied to your clipboard!",

    "error_modal__default_title": "Oops, something went wrong.",
    "error_modal__default_message": "Something went wrong. Try to reload the page or check back later.",
    "error_modal__network_title": "Network error occurred",
    "error_modal__network_message": "Please check your network connection.",

    "subscribers_nofity_modal__title": "Awesome online group selfie! 🔥",
    "subscribers_nofity_modal__message": "Wanna share it with the participants? Let us know and we’ll send them an email. 😉",
    "subscribers_nofity_modal__btn_send": "Send",
    "subscribers_nofity_modal__btn_cancel": "Not now",

    "error__api_code_1": "Internal error",
    "error__api_code_2": "Incorrect params",
    "error__api_code_3": "Processing failure",
    "error__api_code_401": "Unauthorized request",
    "error__api_code_404": "Not found",
    "error__api_code_410": "The requested content is deleted",
    "error__api_code_415": "The selected file is in an invalid format",

    "processing_text_1": "Working on your portrait... 🖼",
    "processing_text_2": "A little selfie party never killed nobody 🥳",
    "processing_text_3": "It's gonna be an epic group portrait. We bet! 😎",
    "processing_text_4": "Almost there... Best selfies comes to those who wait ⏳",

    "contact_us": "Contact us",
    "title": "Start your virtual group selfie",
    "description": "Engage anyone in cool online group portrait creation. Start with adding your photo, invite whomever you want, and share your epic selfie with the world.",
  },
};
