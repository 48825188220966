import React from "react";
import AppContext from "../contexts/AppContext";
import Loading from "../components/Loading";
import {generatePath} from "react-router";
import routes from "../routes";
import FileChooseButton from "../components/FileChooseButton";
import {SvgSprite} from "../components/SvgSprite";
import ImageView from "../components/ImageView";
import * as api from "../utils/api";
import * as collageUtils from "../utils/collage";
import {openWindow} from "../utils/window";
import ErrorModal from "../components/ErrorModal";
import i18n from "../i18n";
import {ApiResponseError} from "../utils/api";
import {apiResponseErrorCodes} from "../utils/api";
import {copyText, copyTextWithInvisibleElement, getLocationQueryObject} from "../utils/text";
import {logEvent, userEvents} from "../utils/log";
import PageHeaderView from "../components/PageHeaderView";
import {CopyToast} from "../components/CopyToast";
import {webviewShare, webviewShareDownload, webviewShareFacebook} from "../utils/webview";
import SubscribersNofityModal from "../components/SubscribersNofityModal";
import CollageSetEmailModal from "../components/CollageSetEmailModal";
import ClientStorage from "../utils/client-storage";

const FETCH_INTERVAL = 1000;

function isMobileScreenSize() {
  return window.innerWidth <= 950;
}

export default class CollagePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isMobileScreen: isMobileScreenSize(),
      collage: null,
      copyToastIsVisible: false,
    };

    this.fetchTimer = null;
  }

  componentDidMount() {
    this.fetchCollage();

    window.addEventListener("resize", this.handleScreenResize);
  }

  componentWillUnmount() {
    clearTimeout(this.fetchTimer);
    window.removeEventListener("resize", this.handleScreenResize);
  }

  handleScreenResize = () => {
    const isMobileScreen = isMobileScreenSize();
    if (isMobileScreen !== this.state.isMobileScreen) {
      this.setState({isMobileScreen});
    }
  };

  fetchCollage = () => {
    api.fetchCollage(this.props.match.params.hash)
      .then((res) => this.handleCollage(res.collage))
      .catch(this.handleError)
  };

  handleCollage = (collage) => {
    if (this.state.collage === null) {
      const query = getLocationQueryObject();

      logEvent(userEvents.PAGE_COLLAGE, {
        collage_id: collage.id,
        style: collage.style,
        is_owner: collage.is_owner,
        ref: query.ref || undefined,
      });
    }

    const nextState = {
      collage,
    };

    document.title = collage.caption
        ? i18n.t("collage_page__meta_title_with_caption", {caption: collage.caption})
        : i18n.t("collage_page__meta_title");

    if (collageUtils.isProcessed(collage)) {
      nextState.isLoading = false;
    } else if (collageUtils.isFailed(collage)) {
      this.handleCollageError(collage);
    } else {
      this.fetchTimer = setTimeout(this.fetchCollage, FETCH_INTERVAL);
    }

    this.setState(nextState);
  };

  handleCollageError = (collage) => {
    this.context.pushModal(ErrorModal, {
      key: "CollagePage-ErrorModal",
      buttons: ErrorModal.getDefaultReloadButton(),
    });
  };

  handleError = (err) => {
    if (window.appConfig.isDebug) {
      console.error(err);
    }

    const props = {
      key: "CollagePage-ErrorModal",
      buttons: <React.Fragment>
        <button
          className="btn btn_width-big btn_paint"
          children={i18n.t("error_modal__reload_page")}
          onClick={() => window.location.reload()} />
      </React.Fragment>,
    };

    if (err instanceof ApiResponseError) {
      switch (err.code) {
        case apiResponseErrorCodes.NOT_FOUND: {
          props.message = i18n.t("collage_page__collage_not_found");
          break;
        }
        case apiResponseErrorCodes.DELETED: {
          props.message = i18n.t("collage_page__collage_is_deleted");
          break;
        }
        default:
          break;
      }
    }

    this.context.pushModal(ErrorModal, {...props});
  };

  handleManageButtonClick = () => {
    this.props.history.push(generatePath(routes.MANAGE, {
      hash: this.state.collage.hash,
      token: this.state.collage.token
    }));
  };

  handleShareToFacebookButtonClick = () => {
    logEvent(userEvents.SHARE, {
      collage_id: this.state.collage.id,
      style: this.state.collage.style,
      provider: "facebook",
      page: "collage",
    });

    api.logCollageShare(this.state.collage.id, "facebook").catch(() => {/* ignore */});

    const shareUrl = new URL(this.state.collage.urls.share);
    shareUrl.searchParams.set("ref", "facebook");

    if (window.clientConfig.isWebview) {
      webviewShareFacebook(shareUrl.toString(), this.state.collage.file.url);
    } else {
      openWindow(
        `https://www.facebook.com/dialog/share?app_id=${window.appConfig.facebook.appId}`
          + `&display=popup&href=${encodeURIComponent(shareUrl.toString())}`,
        "Facebook Share"
      );
    }
  };

  handleShareToSnapchatButtonClick = () => {
    logEvent(userEvents.SHARE, {
      collage_id: this.state.collage.id,
      style: this.state.collage.style,
      provider: "snapchat",
      page: "collage",
    });

    api.logCollageShare(this.state.collage.id, "snapchat").catch(() => {/* ignore */});

    const shareUrl = new URL(this.state.collage.urls.share);
    shareUrl.searchParams.set("ref", "snapchat");

    if (window.clientConfig.isWebview) {
      // webviewShareSnapchatImage(shareUrl.toString(), this.state.collage.file.url);
      const sw = window.clientConfig.isWebviewIOS
        ? window.screen.width
        : Math.floor(window.screen.width * (window.devicePixelRatio || 1));

      webviewShare({
        providers: "[11]",
        caption_text: "",
        sticker_url: encodeURIComponent(this.state.collage.file.url),
        attachment_url: encodeURIComponent(shareUrl),
        sc_sticker_posx: 0.5,
        sc_sticker_posy: 0.5,
        sc_sticker_width: Math.floor(sw * 0.7),
        sc_sticker_height: Math.floor(sw * 0.7),
        sc_sticker_rotation: 0.0,
      });
    } else {
      if (this.snapchatShareButton) {
        this.snapchatShareButton.click();
      } else {
        this.snapchatShareButton = document.createElement("button");
        this.snapchatShareButton.setAttribute("data-share-url", shareUrl.toString());
        window.snap.creativekit.initalizeShareButtons([this.snapchatShareButton]);
        setTimeout(() => this.snapchatShareButton.click(), 200);
      }
    }
  };

  handleCopyLinkButtonClick = () => {
    logEvent(userEvents.SHARE, {
      collage_id: this.state.collage.id,
      style: this.state.collage.style,
      provider: "copylink",
      page: "collage",
    });

    this.handleCopyLink();
  };

  handleCopyLink = () => {
    const url = new URL(this.state.collage.urls.share);
    url.searchParams.set("ref", "link");

    const onCopySuccess = () => {
      this.setState({copyToastIsVisible: true});
      this.copyToastTimer = setTimeout(this.handleCopyToastClose, 5000);
    };

    copyText(url.toString())
      .then(onCopySuccess)
      .catch(() => copyTextWithInvisibleElement(url.toString()))
      .then(onCopySuccess);
  };

  handleCopyToastClose = () => {
    this.setState({copyToastIsVisible: false});
    clearTimeout(this.copyToastTimer);
  };

  handleDownloadButtonClick = () => {
    logEvent(userEvents.DOWNLOAD, {
      collage_id: this.state.collage.id,
      style: this.state.collage.style,
      is_owner: this.state.collage.is_owner,
      page: "collage",
    });

    if (SubscribersNofityModal.shouldBeShown(this.state.collage)) {
      this.context.pushModal(SubscribersNofityModal, {
        key: "CollagePage-SubscribersNofityModal",
        collage: this.state.collage,
      });
    }

    if (window.clientConfig.isWebview) {
      webviewShareDownload(this.state.collage.file.url);
    } else {
      const downloadUrl = new URL(this.state.collage.urls.download);

      window.location.href = downloadUrl.toString();
    }
  };

  handleAttachFileSelected = (file) => {
    this.props.history.push(routes.ATTACH, {file, collage: this.state.collage});
  };

  handleCreateNewCollageFileSelected = (file) => {
    this.props.history.push(routes.CREATE, {file});
  };

  handleInviteFriendsButtonClick = () => {
    if (this.state.collage.is_owner_email_attached) {
      this.handleCopyLink();
      return;
    }

    logEvent(userEvents.INVITE_PEOPLE_MODAL_OPEN, {
      collage_id: this.state.collage.id,
      style: this.state.collage.style,
    });

    this.context.pushModal(CollageSetEmailModal, {
      key: "CollagePage_CollageSetEmailModal",
      collage: this.state.collage,
      onSuccess: () => this.handleCopyLink(),
      onDismissed: () => this.setState({collage: this.state.collage}),
    });
  };

  renderLeftColumnCombine = () => {
    const text1 = this.state.collage.is_finalized
        ? i18n.t("collage_page__guest_title_1_finalized")
        : i18n.t("collage_page__guest_title_1");
    const text2 = this.state.collage.is_finalized
        ? i18n.t("collage_page__guest_subtitle_1_finalized")
        : i18n.t("collage_page__guest_subtitle_1");

    const isManageMessageDisable = !this.state.collage.is_owner
      || (this.state.collage.is_owner && !this.state.collage.is_owner_email_attached)
      || (this.state.collage.is_owner && ClientStorage.getManageMessageIsDisabled(this.state.collage.id));

    const invitePeopleButtonText = [1, 2, 3, 6, 7].includes(window.clientConfig.splitsGroupId)
      ? i18n.t("collage_page__add_friends_button")
      : i18n.t("collage_page__invite_friends_button");

    return <React.Fragment>
      <div className="result-page__content-container result-page__column_1 result-page__column_1-1">
        <div className="invite-container" hidden={!this.state.collage.is_owner || this.state.collage.is_owner_email_attached}>
          <button
            className="btn btn_paint"
            children={invitePeopleButtonText}
            onClick={this.handleInviteFriendsButtonClick} />
        </div>
        <ManageMessageView
          hidden={isManageMessageDisable}
          collage={this.state.collage}
          onManageClick={this.handleManageButtonClick} />
        <h2 className="title-h2 result-page__title" dangerouslySetInnerHTML={{__html: text1}} />
        <p className="result-page__text" dangerouslySetInnerHTML={{__html: text2}} />
        <FileChooseButton
          hidden={this.state.collage.is_finalized}
          className="btn btn_width-big btn_paint result-page-btn_upload-collage-owner"
          children={i18n.t("collage_page__attach_guest_photo_button")}
          place="CollagePage_AttachPhoto"
          isOwner={this.state.collage.is_owner}
          onFileSelected={this.handleAttachFileSelected} />

        {!this.state.isMobileScreen && this.renderCreateNewCollageSection()}
      </div>
    </React.Fragment>;
  };

  renderCreateNewCollageSection = () => {
    return <React.Fragment>
      <h3 className="title-h3" dangerouslySetInnerHTML={{__html: i18n.t("collage_page__guest_title_2")}} />
      <p dangerouslySetInnerHTML={{__html: i18n.t("collage_page__guest_subtitle_2")}} />

      <FileChooseButton
        className="btn btn_width-big btn_transparent mt-2"
        children={i18n.t("collage_page__create_new_collage_button")}
        place="CollagePage_NewCollage_Guest"
        isOwner={this.state.collage.is_owner}
        onFileSelected={this.handleCreateNewCollageFileSelected} />
    </React.Fragment>
  };

  renderRightColumnCombine = () => {
    const collageFile = (this.state.collage.preview_caption_file
      || this.state.collage.preview_file
      || this.state.collage.file);

    // селеб показываем только для гостей если есть превью файл (превью всегда с селебами)
    // и только для автора когда он один на фото (т.е. file.id == preview_file.id)
    const celebsIsVisible = collageUtils.withCelebsStyle(this.state.collage.style)
      && !!this.state.collage.preview_file;

    return <React.Fragment>
      <div className="result-page__column result-page__column_2">
        <div>
          <div className="result-page__collage-title-container" hidden={!celebsIsVisible}>
            <p className="result-page__collage-subtitle" dangerouslySetInnerHTML={{__html: i18n.t("result_page__collage_subtitle")}} />
          </div>
          <ImageView
              hostClassName="result-page__collage"
              image={collageFile}
              backgrounded />
        </div>
      </div>

      <div className="result-page__content-container">
        {this.state.isMobileScreen && this.renderCreateNewCollageSection()}
      </div>
    </React.Fragment>;
  };

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    return <main className="main-section result-page">
      <div className="container">
        <PageHeaderView />

        <div className={"result-page__content " + (!this.state.collage.is_owner ? "guest" : "owner")}>
          <React.Fragment>
            {this.renderLeftColumnCombine()}
            {this.renderRightColumnCombine()}
          </React.Fragment>
        </div>
      </div>

      {this.state.copyToastIsVisible && <CopyToast onCloseButtonClick={this.handleCopyToastClose} />}
    </main>;
  }
}

CollagePage.contextType = AppContext;

class ManageMessageView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: false,
    };
  }

  handleCloseClick = (e) => {
    e.preventDefault();

    this.setState({
      hidden: true,
    });

    ClientStorage.setManageMessageIsDisabled(this.props.collage.id);
  };

  handleManageClick = (e) => {
    e.preventDefault();

    if(e.target.tagName.toLowerCase() === "a") {
      this.props.onManageClick();
    }
  };

  render() {
    if (this.props.hidden || this.state.hidden) {
      return <React.Fragment />;
    }

    return <div className="manage-container">
      <button onClick={this.handleCloseClick}>
        <SvgSprite viewBox="0 0 12 12" icon="icon-close-tooltip" />
      </button>
      <SvgSprite className="manage-container__icon" viewBox="0 0 36 36" icon="icon-manage-container" />
      <p onClick={this.handleManageClick}
         className="manage-container__text"
         dangerouslySetInnerHTML={{__html: i18n.t("collage_page__moderate_text")}} />
    </div>
  }
}