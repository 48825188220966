import React from "react";
import AppContext from "../contexts/AppContext";

export default class ModalWrapper extends React.Component {

  constructor(props) {
    super(props);

    this.getUpperModal = this.getUpperModal.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
    this.catchRef = this.catchRef.bind(this);
    this.handleHistoryBack = this.handleHistoryBack.bind(this);

    this.modalsRefs = {};
  }

  componentDidMount() {
    document.body.addEventListener("keydown", this.handleKeydown);
  }

  componentWillUnmount() {
    document.body.removeEventListener("keydown", this.handleKeydown);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.context.modals.length) {
      document.body.classList.add("-show-popup");
      this.historyUnblock = this.props.history.block(this.handleHistoryBack);
    } else {
      document.body.classList.remove("-show-popup");
      this.historyUnblock && this.historyUnblock();
    }
  }

  getUpperModal() {
    return this.context.modals.length
      ? this.context.modals[this.context.modals.length - 1]
      : null;
  }

  handleHistoryBack(location, action) {
    this.context.clearModals();
    return true;
  }

  handleKeydown(e) {
    if ((e.key || "").toLowerCase() !== "escape") {
      return;
    }

    const modal = this.getUpperModal();
    if (!modal || !modal.props.canBeCloseByEscapeKey) {
      return;
    }

    if (this.modalsRefs[modal.props.key]) {
      this.modalsRefs[modal.props.key].dismiss("escape");
    }
  }

  catchRef(modalKey, ref) {
    this.modalsRefs[modalKey] = ref;
  }

  render() {
    return this.context.modals.map((modal) => {
      return <modal.component {...modal.props} ref={(ref) => this.catchRef(modal.props.key, ref)} />;
    });
  }
}

ModalWrapper.contextType = AppContext;
