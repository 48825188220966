import React from 'react';
import Slider from "react-slick";
import AppContext from "../contexts/AppContext";
import routes from "../routes";
import Loading from "../components/Loading";
import {generatePath} from "react-router";
import ImageView from "../components/ImageView";
import Modal from "../components/Modal";
import {SvgSprite} from "../components/SvgSprite";
import i18n from "../i18n";
import * as api from "../utils/api";
import * as collageUtils from "../utils/collage";
import ErrorModal from "../components/ErrorModal";
import {logEvent, userEvents} from "../utils/log";
import {openWindow} from "../utils/window";
import {copyText, copyTextWithInvisibleElement} from "../utils/text";
import {CopyToast} from "../components/CopyToast";
import {webviewShare, webviewShareDownload, webviewShareFacebook} from "../utils/webview";
import SubscribersNofityModal from "../components/SubscribersNofityModal";
import PageHeaderView from "../components/PageHeaderView";

const FETCH_INTERVAL = 1000;

const STATE_ACTION_ATTACH = "attach";
const STATE_ACTION_DETACH = "detach";

const isModerated = (creative) => creative.is_moderated;
const isAttached = (creative) => creative.is_attached;

export default class ManagePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isChanged: false,
      collage: null,
      collageCaption: "",
      captionFormIsVisible: false,
      originalCreatives: [],
      creatives: [],
      bonuses: [],
      selectedBonus: null,
      initialSlide: undefined,
      copyToastIsVisible: false,
    };

    this.fetchTimer = null;
    this.fetchTimerPreviewWithPersons = null;

    this.collageSliderRefDesktop = React.createRef();
    this.collageSliderRefMobile = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const doChangeSlide = (prevState.collage === null && this.state.collage !== null)
      || (prevState.isLoading && this.state.isLoading === false);

    if (doChangeSlide) {
      if (this.collageSliderRefDesktop.current) {
        this.collageSliderRefDesktop.current.slickGoTo(this.state.initialSlide || 2, true);
      }
      if (this.collageSliderRefMobile.current) {
        this.collageSliderRefMobile.current.slickGoTo(this.state.initialSlide || 0, true);
      }
    }
  }

  componentDidMount() {
    this.fetchCollage();
  }

  componentWillUnmount() {
    clearTimeout(this.fetchTimer);
    clearTimeout(this.fetchTimerPreviewWithPersons);
  }

  fetchCollage = () => {
    api.fetchCollage(this.props.match.params.hash, this.props.match.params.token)
      .then(this.handleCollage)
      .catch(this.handleError);
  };

  handleCollage = ({collage, creatives}) => {
    if (this.state.collage === null) {
      logEvent(userEvents.PAGE_MANAGE, {
        collage_id: collage.id,
        style: collage.style,
      });
    }

    const nextState = {
      isLoading: false,
      collage,
      collageCaption: collage.caption,
      bonuses: collageUtils.mapBonuses(collage, BonusTooltipDisney3D),
    };

    // только если обновилось кол-во
    if (this.state.creatives.length !== creatives.length) {
      nextState.creatives = creatives;
    }

    const hasBonusProcessingCollages = collage.bonuses.filter((bonus) => {
      return collageUtils.isProcessing(bonus);
    }).length > 0;

    if (collageUtils.isProcessing(collage) || hasBonusProcessingCollages) {
      clearTimeout(this.fetchTimer);
      this.fetchTimer = setTimeout(this.fetchCollage, FETCH_INTERVAL);
    }

    this.setState(nextState);
  };

  handleError = (err) => {
    this.setState({isLoading: false});

    if (window.appConfig.isDebug) {
      console.error(err);
    }

    this.context.pushModal(ErrorModal, {
      key: "ManagePage-ErrorModal",
      error: err,
      buttons: ErrorModal.getDefaultReloadButton(),
    });
  };

  handleCreativeStateChange = (creative, stateAction) => {
    if (isAttached(creative) && stateAction === STATE_ACTION_DETACH) {
      const attachedCount = this.state.creatives.filter(isAttached).length;
      if (attachedCount === 1) {
        alert(i18n.t("manage_page__collage_cannot_be_empty"));
        return;
      }
    }

    creative.is_moderated = true;

    if (stateAction === STATE_ACTION_ATTACH) {
      creative.is_attached = true;
    } else if (stateAction === STATE_ACTION_DETACH) {
      creative.is_attached = false;
    }

    this.setState({
      isChanged: true,
      creatives: this.state.creatives.slice()
    })
  };

  handleCreativesApplyChangesButtonClick = () => {
    const params = {
      id: this.state.collage.id,
      token: this.props.match.params.token,
      creatives: this.state.creatives.map((creative) => [
        creative.id,
        creative.is_attached ? 1 : 0,
      ])
    };

    this.setState({
      isLoading: true,
      isChanged: false,
    }, () => {
      api.commitCollageCreativesState(params)
        .then(this.handleCollage)
        .catch(this.handleError);
    });
  };

  handlePreviewCollage = ({collage_preview}) => {
    if (collageUtils.isProcessing(collage_preview)) {
      clearTimeout(this.fetchTimerPreviewWithPersons);

      this.fetchTimerPreviewWithPersons = setTimeout(this.fetchPreviewCollage, FETCH_INTERVAL, collage_preview.id);
    }

    if (collageUtils.isProcessed(collage_preview)) {
      this.handleFinalize(collage_preview);
    }
  };

  fetchPreviewCollage = (id) => {
    api.fetchCollagePreview(id)
      .then(this.handlePreviewCollage)
      .catch(this.handleError);
  };

  handleFinalizeButtonClick = () => {
    const attachedCreatives = this.state.creatives.filter(c => c.is_moderated && c.is_attached);

    if (attachedCreatives.length < this.state.collage.max_items) {
      // this.setState({
      //   isLoading: true,
      // }, () => {
      //   api.fillCollagePreview(this.state.collage.hash, true)
      //     .then(this.handlePreviewCollage)
      //     .catch(this.handleError);
      // });

      this.context.pushModal(FinalizeModal, {
        key: "ManagePage-FinalizeModal",
        collage: this.state.collage,
        onSelect: this.handleFinalize,
        handleError: this.handleError,
      });

      return;
    }

    this.handleFinalize();
  };

  handleFinalize = (collagePreview) => {
    logEvent(userEvents.COLLAGE_FINALIZE, {
      collage_id: this.state.collage.id,
      style: this.state.collage.style,
    });

    const previewId = collagePreview ? collagePreview.id : null;

    this.setState({isLoading: true}, () => {
      api.finalizeCollage(this.state.collage.id, this.props.match.params.token, previewId)
        .then(this.handleCollage)
        .catch(this.handleError);
    });
  };

  handleShareToFacebookButtonClick = () => {
    logEvent(userEvents.SHARE, {
      collage_id: this.state.collage.id,
      style: this.state.collage.style,
      provider: "facebook",
      page: "manage",
    });

    api.logCollageShare(this.state.collage.id, "facebook").catch(() => {/* ignore */});

    const shareUrl = new URL(this.state.collage.urls.share);
    shareUrl.searchParams.set("ref", "facebook");

    if (window.clientConfig.isWebview) {
      webviewShareFacebook(shareUrl.toString(), this.state.collage.file.url);
    } else {
      openWindow(
        `https://www.facebook.com/dialog/share?app_id=${window.appConfig.facebook.appId}`
        + `&display=popup&href=${encodeURIComponent(shareUrl.toString())}`,
        "Facebook Share"
      );
    }
  };

  handleShareToSnapchatButtonClick = () => {
    logEvent(userEvents.SHARE, {
      collage_id: this.state.collage.id,
      style: this.state.collage.style,
      provider: "snapchat",
      page: "manage",
    });

    api.logCollageShare(this.state.collage.id, "snapchat").catch(() => {/* ignore */});

    const shareUrl = new URL(this.state.collage.urls.share);
    shareUrl.searchParams.set("ref", "snapchat");

    if (window.clientConfig.isWebview) {
      const sw = window.clientConfig.isWebviewIOS
        ? window.screen.width
        : Math.floor(window.screen.width * (window.devicePixelRatio || 1));

      webviewShare({
        providers: "[11]",
        caption_text: "",
        sticker_url: encodeURIComponent(this.state.collage.file.url),
        attachment_url: encodeURIComponent(shareUrl),
        sc_sticker_posx: 0.5,
        sc_sticker_posy: 0.5,
        sc_sticker_width: Math.floor(sw * 0.7),
        sc_sticker_height: Math.floor(sw * 0.7),
        sc_sticker_rotation: 0.0,
      });
    } else {
      if (this.snapchatShareButton) {
        this.snapchatShareButton.click();
      } else {
        this.snapchatShareButton = document.createElement("button");
        this.snapchatShareButton.setAttribute("data-share-url", shareUrl.toString());
        window.snap.creativekit.initalizeShareButtons([this.snapchatShareButton]);
        setTimeout(() => this.snapchatShareButton.click(), 200);
      }
    }
  };

  handleCopyLinkButtonClick = () => {
    logEvent(userEvents.SHARE, {
      collage_id: this.state.collage.id,
      style: this.state.collage.style,
      provider: "copylink",
      page: "manage",
    });

    const url = new URL(this.state.collage.urls.share);
    url.searchParams.set("ref", "link");

    const onCopySuccess = () => {
      this.setState({copyToastIsVisible: true});
      this.copyToastTimer = setTimeout(this.handleCopyToastClose, 3000);
    };

    copyText(url.toString())
      .then(onCopySuccess)
      .catch(() => copyTextWithInvisibleElement(url.toString()))
      .then(onCopySuccess);
  };

  handleCopyToastClose = () => {
    this.setState({copyToastIsVisible: false});
    clearTimeout(this.copyToastTimer);
  };

  handleDownloadButtonClick = () => {
    logEvent(userEvents.DOWNLOAD, {
      collage_id: this.state.collage.id,
      style: this.state.collage.style,
      is_owner: true,
      page: "manage",
    });

    if (SubscribersNofityModal.shouldBeShown(this.state.collage)) {
      this.context.pushModal(SubscribersNofityModal, {
        key: "ManagePage-SubscribersNofityModal",
        collage: this.state.collage,
      });
    }

    const isBonus = this.state.selectedBonus
      && collageUtils.isProcessed(this.state.selectedBonus);

    if (window.clientConfig.isWebview) {
      webviewShareDownload(isBonus
        ? this.state.selectedBonus.file.url
        : this.state.collage.file.url);
    } else {
      const downloadUrl = new URL(this.state.collage.urls.download);

      if (isBonus) {
        downloadUrl.searchParams.set("bonus_id", this.state.selectedBonus.id);
      }

      window.location.href = downloadUrl.toString();
    }
  };

  handleBackButtonClick = () => {
    this.props.history.push(generatePath(routes.COLLAGE, {
      hash: this.state.collage.hash,
    }));
  };

  handleCaptionChanged = (e) => {
    this.setState({collageCaption: e.target.value});
  };

  handleCaptionFormSubmit = (e) => {
    e.preventDefault();

    if (this.state.collageCaption !== this.state.collage.caption) {
      logEvent(userEvents.COLLAGE_CAPTION_UPDATE, {
        collage_id: this.state.collage.id,
        style: this.state.collage.style,
        caption: this.state.collageCaption,
      });

      const params = {
        id: this.state.collage.id,
        token: this.props.match.params.token,
        caption: this.state.collageCaption,
      };

      this.setState({
        captionFormIsVisible: false,
      }, () => {
        api.submitCollageCaption(params)
          .then(this.handleCollage)
          .catch(this.handleError);
      });
    }
  };

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    const pendingCreatives = this.state.creatives.filter(c => c.is_moderated === false);
    const attachedCreatives = this.state.creatives.filter(c => c.is_moderated && c.is_attached);
    const detachedCreatives = this.state.creatives.filter(c => c.is_moderated && !c.is_attached);

    const pageTitle = this.state.collage.is_finalized
      ? i18n.t("manage_page__title_finalized")
      : i18n.t("manage_page__title");

    const pageText = this.state.collage.is_finalized
      ? i18n.t("manage_page__text_finalized")
      : i18n.t("manage_page__text");

    const attachedText = this.state.collage.is_premoderation
        ? i18n.t("manage_page__list_approved")
        : i18n.t("manage_page__list_attached");

    const collage = {
      file: this.state.collage.caption_file || this.state.collage.file,
      style: this.state.collage.style,
      status: this.state.collage.status,
    };

    const sliderSettingsDesktop = {
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [{
        breakpoint: 950,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }]
    };

    const sliderSettingsMobile = Object.assign({}, sliderSettingsDesktop);

    sliderSettingsDesktop.ref = this.collageSliderRefDesktop;
    sliderSettingsDesktop.afterChange = (index) => this.setState({
      initialSlide: index,
      // на десктопе бонусы идут в обратном порядке - инвертируем индекс, чтобы получить правильный бонус
      selectedBonus: this.state.bonuses[Math.abs(index - (this.state.bonuses.length - 1))] || null,
    });

    sliderSettingsMobile.ref = this.collageSliderRefMobile;
    sliderSettingsMobile.afterChange = (index) => this.setState({
      initialSlide: index,
      // на мобиле первым идёт коллаж, потому делаем -1 от индекса, чтобы получить правильный бонус
      selectedBonus: this.state.bonuses[index - 1] || null,
    });

    return <main className="main-section manage-page">
      <div className="container">
        <PageHeaderView />

        <div className="manage-page__preview">
          <div className="manage-page__title-block">
            <h2 className="title-h2 manage-page__title" dangerouslySetInnerHTML={{__html: pageTitle}} />
            <p className="manage-page__text" dangerouslySetInnerHTML={{__html: pageText}} />
          </div>

          <div className="manage-page__btns-block">
            <div className="manage-page__btns-block-create"  hidden={this.state.creatives.length < 2 || !this.state.isChanged}>
              <button
                className={"btn btn_small btn_paint " + (this.state.isChanged ? "" : "btn_disabled")}
                dangerouslySetInnerHTML={{__html: i18n.t("manage_page__apply_button")}}
                onClick={this.handleCreativesApplyChangesButtonClick} />
            </div>
            <div className="share-container share-container_small">
              <h4 className="share-container__title" dangerouslySetInnerHTML={{__html: i18n.t("collage_page__share_save__label")}} />
              <button
                className="share-container__btn share-container__btn-download-small"
                onClick={this.handleDownloadButtonClick}>
                <SvgSprite className="share-container__btn-icon" viewBox="0 0 80 80" icon="icon-download-big" />
              </button>
              <button
                className="share-container__btn"
                onClick={this.handleCopyLinkButtonClick}>
                <SvgSprite className="share-container__btn-icon" viewBox="0 0 80 80" icon="icon-copy" />
              </button>
              <button
                hidden={!this.context.installedApps.facebook}
                className="share-container__btn"
                onClick={this.handleShareToFacebookButtonClick}>
                <SvgSprite className="share-container__btn-icon" viewBox="0 0 80 80" icon="icon-fb" />
              </button>
              <button
                hidden={window.clientConfig.isWeb || !this.context.installedApps.snapchat}
                className="share-container__btn"
                onClick={this.handleShareToSnapchatButtonClick}>
                <SvgSprite className="share-container__btn-icon" viewBox="0 0 80 80" icon="icon-snapchat" />
              </button>
            </div>
          </div>
        </div>
        <div className="manage-page__content">
            <div className="manage-page__collage-block">
              <Slider {...sliderSettingsDesktop} className="manage-page__collage-block-processing desktop manage-page__slider">
                <div key="empty" className="manage-page__slide"><div className="empty-slide" /></div>
                {this.state.bonuses.map((bonus) => <SlideView key={`bonus_${bonus.style}`} data={bonus} />).reverse()}
                <SlideView key="collage" data={collage} />
              </Slider>
              <Slider {...sliderSettingsMobile} className="manage-page__collage-block-processing mobile manage-page__slider">
                <SlideView key="collage" data={collage} />
                {this.state.bonuses.map((bonus) => <SlideView key={`bonus_${bonus.style}`} data={bonus} />)}
              </Slider>

              <CaptionView
                caption={this.state.collage.caption}
                onChangeClick={() => this.setState({captionFormIsVisible: !this.state.captionFormIsVisible})} />

              <CaptionFormView
                isVisible={this.state.captionFormIsVisible}
                caption={this.state.collageCaption}
                onFormSubmit={this.handleCaptionFormSubmit}
                onCaptionFieldChange={this.handleCaptionChanged} />

              <div className="manage-page__btns-container">
                <button hidden={true}
                  className="manage-page__btn-download btn btn_small btn_text"
                  onClick={this.handleBackButtonClick}>
                  <SvgSprite className="result-page__action-btn-icon" viewBox="0 0 16 16" icon="icon-back" />
                  {i18n.t("collage_page__back_button")}
                </button>
              </div>
            </div>

            <div className="manage-page__edit-block">
              <div className="manage-page__edit-container">

                <div className="manage-page__pending-block pending-block" hidden={pendingCreatives.length === 0}>
                  <h3 className="pending-block__title" dangerouslySetInnerHTML={{__html: i18n.t("manage_page__list_pending")}} />
                  <div className="pending-block__list">
                    {pendingCreatives.map((creative) => <CreativeView
                      key={creative.id}
                      creative={creative}
                      showButtons
                      onCreativeStateChange={this.handleCreativeStateChange}
                    />)}
                  </div>
                </div>

                <div className="manage-page__pending-block added-block">
                  <h3 className="pending-block__title" dangerouslySetInnerHTML={{__html: attachedText}} />
                  <div className="pending-block__list">
                    {attachedCreatives.map((creative) => <CreativeView
                      key={creative.id}
                      creative={creative}
                      showButtons={attachedCreatives.length > 1}
                      onCreativeStateChange={this.handleCreativeStateChange}
                    />)}
                  </div>
                </div>

                <div className="manage-page__pending-block removed-block" hidden={detachedCreatives.length === 0}>
                  <h3 className="pending-block__title" dangerouslySetInnerHTML={{__html: i18n.t("manage_page__list_detached")}} />
                  <div className="pending-block__list">
                    {detachedCreatives.map((creative) => <CreativeView
                      key={creative.id}
                      creative={creative}
                      showButtons
                      onCreativeStateChange={this.handleCreativeStateChange}
                    />)}
                  </div>
                </div>

              </div>
            </div>
          </div>
      </div>

      {this.state.copyToastIsVisible && <CopyToast onCloseButtonClick={this.handleCopyToastClose} />}
    </main>
  }
}

ManagePage.contextType = AppContext;

class SlideView extends React.Component {

  state = {
    tooltipIsVisible: true,
  };

  render() {
    const Tooltip = this.state.tooltipIsVisible && this.props.data.tooltip;
    const isProcessed = collageUtils.isProcessed(this.props.data) || this.props.data.status === null;
    const isProcessing = collageUtils.isProcessing(this.props.data);

    return <div className="manage-page__slide">
      {isProcessed && <img className="manage-page__slide-img" src={this.props.data.file.url} alt="Collage" />}
      {isProcessing && <Loading className="loader__processing" withoutText />}
      {this.props.data.text && !isProcessing && <p className="slide-caption" dangerouslySetInnerHTML={{__html: this.props.data.text}} />}
      {Tooltip && !isProcessing && <Tooltip onCloseClick={() => this.setState({tooltipIsVisible: false})} />}
    </div>
  }
}

function BonusTooltipDisney3D(props) {
  return <div className="slide-tooltip">
    <SvgSprite className="slide-tooltip-icon" viewBox="0 0 56 56" icon="icon-slide-tooltip" />
    <div>
      <h3 dangerouslySetInnerHTML={{__html: i18n.t("manage_page__bonus_tooltip_title_disney_3d")}} />
      <p dangerouslySetInnerHTML={{__html: i18n.t("manage_page__bonus_tooltip_text_disney_3d")}} />
    </div>
    <a href="#close" onClick={props.onCloseClick}>
      <SvgSprite className="slide-tooltip-close" viewBox="0 0 12 12" icon="icon-close-tooltip" />
    </a>
  </div>;
}

function CreativeView({creative, onCreativeStateChange, showButtons}) {
  const canAttach = !isAttached(creative);
  const canDetach = isAttached(creative) || !isModerated(creative);

  return <React.Fragment>
    <div className="pending-block__item">
      <img className="pending-block__image" src={creative.file.url} alt={"Creative " + creative.id} />
      <button
        className="pending-block__btn pending-block__btn-check"
        hidden={!showButtons || !canAttach}
        onClick={() => onCreativeStateChange(creative, STATE_ACTION_ATTACH)}  >
        <SvgSprite className="pending-block__btn-icon" viewBox="0 0 16 16" icon="icon-check" />
      </button>
      <button
        className="pending-block__btn pending-block__btn-remove"
        hidden={!showButtons || !canDetach}
        onClick={() => onCreativeStateChange(creative, STATE_ACTION_DETACH)} >
          <SvgSprite className="pending-block__btn-icon" viewBox="0 0 14 16" icon="icon-remove" />
      </button>
    </div>
  </React.Fragment>
}

class FinalizeModal extends Modal {

  constructor(props) {
    super(props);

    this.state = {
      previewWithPersons: null,
      previewWithoutPersons: null,
    };

    this.fetchTimerPreviewWithPersons = null;
    this.fetchTimerPreviewWithoutPersons = null;
  }

  componentDidMount() {
    api.fillCollagePreview(this.props.collage.hash, true)
      .then(this.handlePreviewCollage)
      .catch(this.props.handleError);

    api.fillCollagePreview(this.props.collage.hash, false)
      .then(this.handlePreviewCollage)
      .catch(this.props.handleError);
  }

  componentWillUnmount() {
    clearTimeout(this.fetchTimerPreviewWithPersons);
    clearTimeout(this.fetchTimerPreviewWithoutPersons);
  }

  fetchPreviewCollage = (id) => {
    api.fetchCollagePreview(id)
      .then(this.handlePreviewCollage)
      .catch(this.props.handleError);
  };

  handlePreviewCollage = ({collage_preview}) => {
    if (collageUtils.isProcessing(collage_preview)) {
      const timerId = collage_preview.with_placeholders
        ? "fetchTimerPreviewWithPersons"
        : "fetchTimerPreviewWithoutPersons";

      clearTimeout(this[timerId]);

      this[timerId] = setTimeout(this.fetchPreviewCollage, FETCH_INTERVAL, collage_preview.id);
    }

    if (collageUtils.isProcessed(collage_preview)) {
      const state = collage_preview.with_placeholders
        ? {previewWithPersons: collage_preview}
        : {previewWithoutPersons: collage_preview};

      this.setState(state);
    }
  };

  handlePreviewSelect = (preview) => {
    this.dismiss();
    this.props.onSelect(preview);
  };

  renderModal() {
    const previewWithPersonsIsProcessed = this.state.previewWithPersons && collageUtils.isProcessed(this.state.previewWithPersons);
    const previewWithPersonsIsProcessing = this.state.previewWithPersons === null || (this.state.previewWithPersons && collageUtils.isProcessing(this.state.previewWithPersons));
    const previewWithoutPersonsIsProcessed = this.state.previewWithoutPersons && collageUtils.isProcessed(this.state.previewWithoutPersons);
    const previewWithoutPersonsIsProcessing = this.state.previewWithoutPersons === null || (this.state.previewWithoutPersons && collageUtils.isProcessing(this.state.previewWithoutPersons));
    const bothIsProcessed = previewWithPersonsIsProcessed && previewWithoutPersonsIsProcessed;
    const anyIsProcessing = previewWithPersonsIsProcessing || previewWithoutPersonsIsProcessing;

    return <div className="modal__content">
      <h2 className="title-h2 modal__title"
          dangerouslySetInnerHTML={{__html: i18n.t("manage_page__finalize_modal__title")}} />
      <p className="modal__text"
         dangerouslySetInnerHTML={{__html: i18n.t("manage_page__finalize_modal__text")}} />

      {anyIsProcessing && <Loading className="loader__previews loader_small" evenWhenModal />}
      {bothIsProcessed && <div className="modal__collage">
        <div className="modal__collage-item" onClick={() => this.handlePreviewSelect(this.state.previewWithPersons)}>
          <ImageView image={this.state.previewWithPersons.file} backgrounded />
        </div>
        <div className="modal__collage-item" onClick={() => this.handlePreviewSelect(this.state.previewWithoutPersons)}>
          <ImageView image={this.state.previewWithoutPersons.file} backgrounded />
        </div>
      </div>}

      <button
        className="btn btn_width-big btn_transparent mt-4"
        children={i18n.t("cancel")}
        onClick={() => this.dismiss()} />

    </div>
  }
}

function CaptionView({caption, onChangeClick}) {
  const buttonText = caption
    ? i18n.t("manage_page__change_caption_button")
    : i18n.t("manage_page__set_caption_button");

  return <div className="caption-container">
    <button onClick={onChangeClick}>
      <SvgSprite className="share-container__btn-icon" viewBox="0 0 80 80" icon="icon-caption" />
      <span children={buttonText} />
    </button>
  </div>;
}

class CaptionFormView extends React.Component {

  constructor(props) {
    super(props);

    this.fieldRef = React.createRef();
  }

  componentDidMount() {
    if (this.fieldRef && this.fieldRef.current) {
      this.fieldRef.current.focus();
    }
  }

  render() {
    const {
      caption,
      isVisible,
      onFormSubmit,
      onCaptionFieldChange
    } = this.props;

    return <form className="caption-form" onSubmit={onFormSubmit} hidden={!isVisible}>
      {/* <h3 className="caption-form__title" dangerouslySetInnerHTML={{__html: i18n.t("caption_form_title")}}/> */}
      <div className="caption-form__container">
        <input
          ref={this.fieldRef}
          className="caption-form__input"
          type="text"
          placeholder={i18n.t("manage_page__caption_placeholder")}
          maxLength="250"
          autoFocus
          value={caption}
          onChange={onCaptionFieldChange} />
        <button
          type="submit"
          className="caption-form__button btn btn_small btn_paint"
          dangerouslySetInnerHTML={{__html: i18n.t("caption_form_button")}}/>
      </div>
    </form>;
  }
}