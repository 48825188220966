export const aliases = {
  PREVIEW: "preview",
  BODY: "body",
  RESULT: "result",
};

export const statuses = {
  failed: -1,
  pending: 0,
  processing: 1,
  processed: 2,
};

export function isProcessed(creative) {
  return creative.status === statuses.processed;
}

export function isFailed(creative) {
  return creative.status === statuses.failed;
}

export function isProcessing(creative) {
  return creative.status === statuses.pending || creative.status === statuses.processing;
}

export function isPending(creative) {
  return creative.status === statuses.pending;
}

export function isResult(creative) {
  return creative.alias === aliases.RESULT;
}

export function isPreview(creative) {
  return creative.alias === aliases.PREVIEW;
}

export function isBody(creative, sourceCreativeId) {
  return creative.alias === (aliases.BODY + (sourceCreativeId && (":" + sourceCreativeId)));
}

export function isTemplateId(creative, templateId) {
  // eslint-disable-next-line eqeqeq
  return creative.template.id == templateId;
}