export default {
  INDEX: "/",
  CREATE: "/create",
  FINISH: "/create/finish/:hash",
  EDIT: "/edit",
  ATTACH: "/attach",
  COLLAGE: "/c/:hash",
  COLLAGE_DOWNLOAD: "/c/:hash/download",
  MANAGE: "/m/:hash/:token",
  SNAPCHAT_TRANSITION: "/snapchat/:hash",
};
