import React from 'react';
import routes from "../routes";
import AppContext from "../contexts/AppContext";
import Loading from "../components/Loading";
import {SvgSprite} from "../components/SvgSprite";
import i18n from "../i18n";
import * as api from "../utils/api";
import * as creativeUtils from "../utils/creative";
import * as collageUtils from "../utils/collage";
import {generatePath} from "react-router";
import FileChooseButton from "../components/FileChooseButton";
import {emailIsValid} from "../utils/text";
import {ApiResponseError} from "../utils/api";
import CreativeErrorModal from "../components/CreativeErrorModal";
import ErrorModal from "../components/ErrorModal";
import Modal from "../components/Modal";
import {logEvent, userEvents} from "../utils/log";
import PageHeaderView from "../components/PageHeaderView";
import {assetUrl} from "../utils/etc";
import BodyChooserModal, {bodiesTemplatesList} from "../components/BodyChooserModal";
import ClientStorage from "../utils/client-storage";

const FETCH_INTERVAL = 1000;

const enabledStyles = [
  collageUtils.styles.cartoonVectorBody,
  collageUtils.styles.cartoon,
  collageUtils.styles.vector,
];

if (window.appConfig.isDebug) {
  enabledStyles.push(collageUtils.styles.vectorBody);
  enabledStyles.push(collageUtils.styles.toonVectorBody);
}

const MAX_ITEMS = [
  /*1, 2, 3, 4,*/ 5, 6, 7, 8, /*9,*/ 10,
  11, 12, 13, 14, 15, 16, 17, 18, 19, /*20,*/
  21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
  /*31,*/ 32, 33, 34, 35, 36, 37, 38, 39, 40,
  41, 42, 43, 44, 45, 46, 47, /*48,*/ 49, 50,
];
const MAX_ITEMS_MIN = MAX_ITEMS[0];
const MAX_ITEMS_MAX = MAX_ITEMS[MAX_ITEMS.length - 1];

const MODERATION_TYPE_PRE = "pre";
const MODERATION_TYPE_POST = "post";

const steps = {
  start: "start",
  email: "email",
  caption: "caption",
  templateSelect: "template_select",
  confirmation: "confirmation",
};

export default class CreatePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isAutomode: window.clientConfig.splitsGroupId > 5,
      file: null,
      fileUrl: null,
      sourcePhoto: null,
      photo: null,
      creatives: [],
      form: {
        style: {
          value: enabledStyles[0],
          error: undefined,
          preview: true,
        },
        email: {
          value: window.appConfig.isDebug ? "" : "",
          error: undefined,
        },
        maxItems: {
          value: MAX_ITEMS_MIN,
          error: undefined,
        },
        moderationType: {
          value: MODERATION_TYPE_POST,
          error: undefined,
        }
      },
      step: steps.start,
      emailTipIsVisible: false,
      maxItemsPreviewIsVisible: true,
      collage: null,
      collageCaption: "",
      selectedCreativeTemplateId: null,
    };

    this.fetchTimer = null;
  }

  componentDidMount() {
    logEvent(userEvents.PAGE_CREATE);

    if (!this.props.location.state || (!this.props.location.state.file && !this.props.location.state.photo)) {
      this.props.history.replace(routes.INDEX);
      return;
    }

    if (this.props.location.state.file) {
      this.setState({
        sourcePhoto: null,
        file: this.props.location.state.file,
        fileUrl: window.clientConfig.isWeb ? URL.createObjectURL(this.props.location.state.file) : null,
      }, () => {
        this.autoSelectStyle();
      });
    } else {
      this.setState({
        sourcePhoto: this.props.location.state.photo,
        file: null,
        fileUrl: this.props.location.state.photo.file.url,
      }, () => {
        this.autoSelectStyle();
      });
    }

    document.title = i18n.t("title");
    document.body.addEventListener("click", this.handleDocumentBodyClick);
  }

  componentWillUnmount() {
    clearTimeout(this.fetchTimer);
    document.body.removeEventListener("click", this.handleDocumentBodyClick);
  }

  handleDocumentBodyClick = (e) => {
    if (!e.target.closest(".tooltip")) {
      this.setState({emailTipIsVisible: false});
    }
  };

  autoSelectStyle = () => {
    if (!this.state.isAutomode) {
      return;
    }

    let style = null;
    if (window.clientConfig.splitsGroupId === 10) {
      style = collageUtils.styles.cartoonVectorBody;
    } else if (window.clientConfig.splitsGroupId === 9) {
      style = collageUtils.styles.cartoon;
    } else if (window.clientConfig.splitsGroupId === 8) {
      style = collageUtils.styles.vector;
    }

    if (style != null) {
      this.setState({
        form: {...this.state.form, style: {value: style}}
      }, () => {
        this.handleFormCreateSubmit();
      });
    }
  };

  createPhoto = () => {
    const isFork = this.state.file === null && this.state.sourcePhoto !== null;
    const sourceObject = isFork ? this.state.sourcePhoto : this.state.file;

    api.createPhoto(sourceObject, {style: this.state.form.style.value})
      .then(this.handlePhotoStatus)
      .catch((err) => {
        logEvent(userEvents.PHOTO_UPLOAD_FAILED, {
          place: "CreatePage",
          style: this.state.form.style.value,
        });

        if (err instanceof ApiResponseError && err.code === 2) {
          this.handleFormError(err);
        } else {
          this.handleError(err);
        }
      });
  };

  createCollage = (creative) => {
    const params = {
      style: this.state.form.style.value,
      email: this.state.form.email.value,
      max_items: this.state.form.maxItems.value,
      is_premoderation: this.state.form.moderationType.value === MODERATION_TYPE_PRE ? 1 : 0,
      split_group: window.clientConfig.splitsGroupId,
    };

    api.createCollage(creative.id, params)
      .then(this.handleCollageStatus)
      .catch(this.handleError);
  };

  showBodyChooserModal = (creative) => {
    if (this.state.isAutomode) {
      this.autoSelectBody(creative);
    } else {
      this.context.pushModal(BodyChooserModal, {
        key: "CreatePage-BodyChooserModal",
        canBeCloseByEscapeKey: true,
        photo: this.state.photo,
        sourceCreativeId: creative.id,
        onSelectedCreative: (creative) => {
          this.setState({isLoading: true}, () => this.createCollage(creative));
        },
      });
    }
  };

  autoSelectBody = (creative) => {
    const gender = this.state.photo.gender.length !== 0
      ? this.state.photo.gender
      : "male";

    const body = bodiesTemplatesList
      .filter((t) => t.gender === gender)
      .random();

    logEvent(userEvents.BODY_SELECTED, {
      gender: body.gender,
      template_id: body.id,
    });

    api.submitPhotoBodyTemplate(this.state.photo.id, creative.id, body.id)
      .then((res) => this.handleAutoSelectBodyStatus(body.id, creative.id, res))
      .catch(this.handleError);
  };

  handleAutoSelectBodyStatus = (bodyId, sourceCreativeId, {photo, creatives}) => {
    this.setState({photo, creatives});

    const creative = creatives.find((creative) => {
      return creativeUtils.isBody(creative, sourceCreativeId)
        && creativeUtils.isTemplateId(creative, bodyId);
    });

    if (creativeUtils.isProcessed(creative)) {
      api.persistCreativeBody(creative.id)
        .then(this.handleAutoSelectBodyPersistStatus)
        .catch(this.handleError);
    } else if (creativeUtils.isFailed(creative)) {
      this.handleCreativeError(creative);
    } else {
      this.fetchTimer = setTimeout(() => {
        api.fetchPhoto(photo.id)
          .then((res) => this.handleAutoSelectBodyStatus(bodyId, sourceCreativeId, res))
          .catch(this.handleError);
      }, FETCH_INTERVAL);
    }
  };

  handleAutoSelectBodyPersistStatus = (creative) => {
    if (creativeUtils.isProcessed(creative)) {
      this.createCollage(creative);
    } else if (creativeUtils.isFailed(creative)) {
      this.handleCreativeError(creative);
    } else {
      this.fetchTimer = setTimeout(() => {
        api.fetchCreative(creative.id)
          .then(this.handleAutoSelectBodyPersistStatus)
          .catch(this.handleError);
      }, FETCH_INTERVAL);
    }
  };

  setFormFieldState = (field, value, error, cb) => {
    this.setState({form: {...this.state.form, [field]: {value, error}}});
  };

  setFormFieldError = (field, error) => {
    this.setState({form: {...this.state.form, [field]: {...this.state.form[field], error}}});
  };

  handleAnotherFileSelectedInForm = (file) => {
    this.setState({
      file: file,
      fileUrl: window.clientConfig.isWeb ? URL.createObjectURL(file) : null,
      sourcePhoto: null,
      photo: null,
      creatives: [],
      selectedCreativeTemplateId: null,
    });
  };

  handleAnotherFileSelected = (file) => {
    this.setState({
      isLoading: true,
      file: file,
      fileUrl: window.clientConfig.isWeb ? URL.createObjectURL(file) : null,
      sourcePhoto: null,
      photo: null,
      creatives: [],
      selectedCreativeTemplateId: null,
    }, this.createPhoto);
  };

  handleFormCreateSubmit = () => {
    logEvent(userEvents.FORM_CREATE_SUBMIT, {
      style: this.state.form.style.value,
    });

    this.setState({isLoading: true}, this.createPhoto);
  };

  handleFormCreateEmailSubmit = (e) => {
    e.preventDefault();

    if (this.state.form.email.value.length === 0) {
      this.setFormFieldError("email", i18n.t("email__error_empty"));
      return;
    }

    if (!emailIsValid(this.state.form.email.value)) {
      this.setFormFieldError("email", i18n.t("email__error_invalid_format"));
      return;
    }

    logEvent(userEvents.FORM_CREATE_EMAIL_SUBMIT, {
      style: this.state.form.style.value,
    });

    this.setState({isLoading: true}, this.createPhoto);
  };

  handleFormError = (err) => {
    this.setState({isLoading: false});
    // todo
  };

  handleEmailTipClick = () => {
    this.context.pushModal(EmailModal, {
      key: "CreatePage-EmailModal",
    });
  };

  handleStyleFormFieldChange = (style) => {
    this.setFormFieldState("style", style);
  };

  handleEmailFormFieldChange = (e) => {
    this.setFormFieldState("email", e.target.value);
  };

  handleMaxItemsFormFieldChange = (e) => {
    let value = parseInt(e.target.value) || 0;
    value = Math.min(value, MAX_ITEMS_MAX);
    value = Math.max(value, MAX_ITEMS_MIN);

    this.setFormFieldState("maxItems", value);
  };

  handleMaxItemsMoreButtonClick = (e) => {
    const moreValue = MAX_ITEMS
      .sort((a, b) => a > b ? 1 : -1)
      .find((value) => value > this.state.form.maxItems.value);

    if (moreValue) {
      this.setFormFieldState("maxItems", moreValue);
    }
  };

  handleToggleMaxItemsPreview = () => {
    this.setState({maxItemsPreviewIsVisible: !this.state.maxItemsPreviewIsVisible});
  };

  handleMaxItemsLessButtonClick = (e) => {
    const lessValue = MAX_ITEMS
      .sort((a, b) => a > b ? -1 : 1)
      .find((value) => value < this.state.form.maxItems.value);

    if (lessValue) {
      this.setFormFieldState("maxItems", lessValue);
    }
  };

  handleModerationTypeFormFieldChange = (e) => {
    this.setFormFieldState("moderationType", e.target.value);
  };

  handleCreativePreviewSelect = (templateId) => {
    logEvent(userEvents.CREATIVE_PREVIEW_SELECT, {
      place: "CreatePage",
      template_id: templateId,
      style: this.state.form.style.value,
    });

    ClientStorage.setPhotoSelectedResultTemplateId(this.state.photo.id, templateId);

    this.setState({
      isLoading: true,
      selectedCreativeTemplateId: templateId,
    }, () => {
      api.submitPhotoTemplate(this.state.photo.id, templateId)
        .then(this.handleCreativeStatus)
        .catch(this.handleError);
    });
  };

  handleCreativeSelectedBodyStatus = (creative) => {
    if (creativeUtils.isProcessed(creative)) {
      this.createCollage(creative);
    } else if (creativeUtils.isFailed(creative)) {
      this.handleCreativeError(creative);
    } else {
      clearTimeout(this.fetchTimer);
      this.fetchTimer = setTimeout(() => {
        api.fetchCreative(creative.id)
          .then(this.handleCreativeSelectedBodyStatus)
          .catch(this.handleError);
      }, FETCH_INTERVAL);
    }
  };

  handleConfirmCreativeButtonClick = () => {
    const creative = this.state.creatives.find((creative) => creativeUtils.isResult(creative));

    if (collageUtils.isBodyStyle(this.state.form.style.value)) {
      this.showBodyChooserModal(creative);
    } else {
      this.setState({isLoading: true}, () => this.createCollage(creative));
    }
  };

  handleCollageStatus = ({collage}) => {
    if (collageUtils.isProcessed(collage)) {
      logEvent(userEvents.COLLAGE_PROCESSED, {
        place: "CreatePage",
        collage_id: collage.id,
        style: collage.style,
      });
      this.props.history.replace(generatePath(routes.FINISH, {hash: collage.hash}), {
        collage: collage,
        photo: this.state.photo,
      });
    } else if (collageUtils.isFailed(collage)) {
      logEvent(userEvents.COLLAGE_FAILED, {
        place: "CreatePage",
        collage_id: collage.id,
        style: collage.style,
      });
      this.props.history.replace(generatePath(routes.COLLAGE, {hash: collage.hash}));
    } else {
      this.fetchTimer = setTimeout(() => {
        api.fetchCollage(collage.hash)
          .then(this.handleCollageStatus)
          .catch(this.handleError);
      }, FETCH_INTERVAL);
    }
  };

  handlePhotoStatus = ({photo, creatives}) => {
    if (this.state.photo === null) {
      logEvent(userEvents.PHOTO_UPLOADED, {
        place: "CreatePage",
        style: photo.style,
      });
    }

    const nextState = {photo, creatives};
    const creative = creatives[0];
    const isResult = creativeUtils.isResult(creative);

    if (creativeUtils.isProcessed(creative)) {
      logEvent(userEvents.CREATIVE_PROCESSED, {
        place: "CreatePage",
        type: isResult ? "result" : "preview",
        style: photo.style,
      });

      if (this.state.isAutomode) {
        if (isResult) {
          this.handleConfirmCreativeButtonClick();
        } else {
          const selectedTemplateId = Object.keys(creative.extra.variants).random();
          this.handleCreativePreviewSelect(selectedTemplateId);
        }
      } else {
        nextState.isLoading = false;
        nextState.step = isResult ? steps.confirmation : steps.templateSelect;
      }
    }
    else if (creativeUtils.isFailed(creative)) {
      logEvent(userEvents.CREATIVE_FAILED, {
        place: "AttachPage",
        type: isResult ? "result" : "preview",
        style: photo.style,
      });

      this.handleCreativeError(creative);
    }
    else {
      clearTimeout(this.fetchTimer);
      this.fetchTimer = setTimeout(() => {
        api.fetchPhoto(nextState.photo.id)
          .then(this.handlePhotoStatus)
          .catch(this.handleError);
      }, FETCH_INTERVAL);
    }

    this.setState(nextState);
  };

  handleCreativeStatus = ({photo, creatives}) => {
    const nextState = {photo, creatives};
    const creative = creatives.find((creative) => {
      return creativeUtils.isResult(creative)
          && creativeUtils.isTemplateId(creative, this.state.selectedCreativeTemplateId);
    });

    if (creativeUtils.isProcessed(creative)) {
      logEvent(userEvents.CREATIVE_PROCESSED, {
        place: "CreatePage",
        type: "result",
        style: photo.style,
      });

      if (collageUtils.isBodyStyle(this.state.form.style.value)) {
        nextState.isLoading = this.state.isAutomode;
        this.showBodyChooserModal(creative);
      } else {
        this.createCollage(creative);
      }
    }
    else if (creativeUtils.isFailed(creative)) {
      logEvent(userEvents.CREATIVE_FAILED, {
        place: "AttachPage",
        type: "result",
        style: photo.style,
      });

      this.handleCreativeError(creative);
    }
    else {
      this.fetchTimer = setTimeout(() => {
        api.fetchPhoto(this.state.photo.id)
          .then(this.handleCreativeStatus)
          .catch(this.handleError);
      }, FETCH_INTERVAL);
    }

    this.setState(nextState);
  };

  handleCreativeError = (creative) => {
    this.context.pushModal(CreativeErrorModal, {
      key: "CreagePage-CreativeErrorModal-" + creative.id,
      creative: creative,
      onFileSelected: this.handleAnotherFileSelected,
    });
  };

  handleError = (err) => {
    this.setState({isLoading: false});

    if (window.appConfig.isDebug) {
      console.error(err);
    }

    let button = ErrorModal.getDefaultReloadButton();
    if (err.code === 415) {
      button = <FileChooseButton
        className="btn btn_width-big btn_paint"
        children={i18n.t("try_another_photo")}
        place="CreatePage_ErrorModal"
        onFileSelected={(file) => {
          this.context.popModal();
          this.handleAnotherFileSelected(file);
        }} />;
    }

    this.context.pushModal(ErrorModal, {
      key: "CreatePage-ErrorModal",
      error: err,
      buttons: button,
    });
  };

  handleCaptionChanged = (e) => {
    this.setState({collageCaption: e.target.value});
  };

  handleCaptionFormSubmit = (e) => {
    e.preventDefault();

    const params = {
      id: this.state.collage.id,
      token: this.state.collage.token,
      caption: this.state.collageCaption,
    };

    this.setState({
      isLoading: true,
    }, () => {
      api.submitCollageCaption(params)
          .then(this.handleCollageStatus)
          .catch(this.handleError);
    });
  };

  handleSkipCaptionButtonClick = (e) => {
    this.setState({
      isLoading: true,
    }, () => {
      this.handleCollageStatus({collage: this.state.collage})
    });
  };

  renderStepStart = () => {
    const submitHandler = (e) => {
      e.preventDefault();
      this.handleFormCreateSubmit();
    };

    return <main className="main-section create-page create-page-1">
      <div className="container">
        <PageHeaderView />

        <form className="form" onSubmit={submitHandler} noValidate id="create-form">
          <div className="form__column form__column_first"  hidden={true}>
            <div className="form__title-container">
              <div className="form__title-block">
                <h2 className="title-h2 form__title">
                  <span className="form__title-value" dangerouslySetInnerHTML={{__html: i18n.t("create_page__form_title_step_1")}} />
                  {/*<button
                    type="button"
                    className="form__tooltip-btn btn_paint"
                    children="?"
                    onClick={this.handleEmailTipClick} />*/}
                </h2>
                {/* <div className="form__tooltip-container tooltip__container">
                  <button
                    className="form__tooltip-btn"
                    children="?"
                    onClick={this.handleEmailTipClick} />
                  <EmailTooltip
                    isVisible={this.state.emailTipIsVisible}
                    onCloseButtonClick={() => this.setState({emailTipIsVisible: false})} />
                  </div>*/}
              </div> 
              <p
                className="form__subtitle"
                dangerouslySetInnerHTML={{__html: i18n.t("create_page__form_message_step_1")}} />
            </div>
          </div>

          <div className="form__column form__column_second">
            <div className="form__column-container">
              <div className="form__column-info">
                <div className="form__type-container" hidden={true}>
                  <h4
                    className="form__type-title form__title-input"
                    dangerouslySetInnerHTML={{__html: i18n.t("create_page__form_moderation_type")}} />
                  <div className="form__type-block">
                    <label htmlFor="form-type2" className="form__label">
                      <input 
                        type="radio" 
                        className="form__radio" 
                        id="form-type2"
                        name="form-type-check"
                        value={MODERATION_TYPE_POST}
                        readOnly
                        checked={this.state.form.moderationType.value === MODERATION_TYPE_POST}
                        onClick={this.handleModerationTypeFormFieldChange} />
                      <span
                        className="form__label-name"
                        dangerouslySetInnerHTML={{__html: i18n.t("create_page__form_moderation_type_post")}} />
                      <span
                        className="form__label-subname"
                        dangerouslySetInnerHTML={{__html: i18n.t("create_page__form_moderation_type_post_tip")}} />
                    </label>
                    <label htmlFor="form-type1" className="form__label">
                      <input
                        type="radio"
                        className="form__radio"
                        id="form-type1"
                        name="form-type-check"
                        value={MODERATION_TYPE_PRE}
                        readOnly
                        checked={this.state.form.moderationType.value === MODERATION_TYPE_PRE}
                        onClick={this.handleModerationTypeFormFieldChange} />
                      <span
                        className="form__label-name"
                        dangerouslySetInnerHTML={{__html: i18n.t("create_page__form_moderation_type_pre")}} />
                      <span
                        className="form__label-subname"
                        dangerouslySetInnerHTML={{__html: i18n.t("create_page__form_moderation_type_pre_tip")}} />
                    </label>
                  </div>
                </div>
                <section className="collage-type">
                  <h2
                    className="title-h2 collage-type__title"
                    dangerouslySetInnerHTML={{__html: i18n.t("create_page__form_collage_type")}} />

                  <ul className="collage-type__list">
                    {enabledStyles.map((style) => <li
                      key={style}
                      className={"collage-type__item " + (this.state.form.style.value === style ? "collage-type__item_check" : "") + " collage-type__item--" + style}
                      onClick={() => this.handleStyleFormFieldChange(style)}
                    />)}
                  </ul>

                  <input
                    form="create-form"
                    type="submit"
                    className="btn btn_paint"
                    value={i18n.t("create_page__form_next")} />
                </section>
              </div>

              <div className="form__avatar-container" hidden={/*!this.state.fileUrl*/true}>
                <h4
                  className="form__avatar-title form__title-input"
                  dangerouslySetInnerHTML={{__html: i18n.t("create_page__form_photo")}} />

                  <div className="form__avatar-content">
                    <div className="form__avatar-block">
                      <img className="form__avatar-image" src={this.state.fileUrl} alt="Selected" />
                    </div>
                    <FileChooseButton
                      className="btn btn_small btn_transparent"
                      children={i18n.t("try_another_photo")}
                      place="CreatePage_PhotoPreview"
                      onFileSelected={this.handleAnotherFileSelectedInForm} />
                    </div>
              </div>
            </div>
            <div className="form__container" hidden={true}>
              <div className="form__number-container">
                <h4
                  className="form__number-title form__title-input"
                  dangerouslySetInnerHTML={{__html: i18n.t("create_page__form_photos_target")}} />
                  <div className="form__number-block">
                    <input
                      type="text"
                      className="form__input form__number"
                      value={this.state.form.maxItems.value}
                      readOnly
                      disabled
                      onChange={this.handleMaxItemsFormFieldChange} />
                    <div className="form__number-btn-block">
                      <button
                        type="button"
                        className="form__number-btn-more btn btn_ex-small btn_paint" 
                        dangerouslySetInnerHTML={{__html: i18n.t("btn_more")}}
                        disabled={this.state.form.maxItems.value === MAX_ITEMS_MAX}
                        onClick={this.handleMaxItemsMoreButtonClick} />
                      <button
                        type="button"
                        className="form__number-btn-less btn btn_ex-small btn_paint" 
                        dangerouslySetInnerHTML={{__html: i18n.t("btn_less")}}
                        disabled={this.state.form.maxItems.value === MAX_ITEMS_MIN}
                        onClick={this.handleMaxItemsLessButtonClick} />
                    </div>
                  </div>
              </div>

              <div className="form__preview">
                <button type="button" className={"btn_text form__preview-btn " + (this.state.maxItemsPreviewIsVisible ? "form__preview-btn_show" : "")} onClick={this.handleToggleMaxItemsPreview}>
                  <SvgSprite className="form__preview-btn-icon" viewBox="0 0 16 16" icon="icon-preview" />
                  <span className="form__preview-btn-value" dangerouslySetInnerHTML={{__html: i18n.t("btn_preview")}} />
                </button>
                <div className={"form__preview-image-container " + (this.state.maxItemsPreviewIsVisible ? "form__preview-image-container_show" : "")}>
                  <img className="form__preview-image"
                       src={assetUrl(`assets/img/collages-previews/${this.state.form.maxItems.value}.jpg`)}
                       alt="Preview" />
                </div>
              </div>
            </div>

            <div className="form__submit-container" hidden={true}>
              <input
                type="submit"
                className="btn btn_paint"
                value={i18n.t("create_page__form_submit")} />
            </div>
          </div>
        </form>

        <section className="collage-type" hidden={true}>
          <h2
            className="title-h2 collage-type__title"
            dangerouslySetInnerHTML={{__html: i18n.t("create_page__form_collage_type")}} />

          <ul className="collage-type__list">
            {enabledStyles.map((style) => <li
              key={style}
              className={"collage-type__item " + (this.state.form.style.value === style ? "collage-type__item_check" : "") + " collage-type__item--" + style}
              onClick={() => this.handleStyleFormFieldChange(style)}
            />)}
          </ul>

          <input
            form="create-form"
            type="submit" 
            className="btn btn_paint" 
            value={i18n.t("create_page__form_next")} />
        </section>
      </div>
    </main>;
  };

  renderStepEmail = () => {
    return <main className="main-section create-page">
      <div className="container">
        <PageHeaderView />

        <form className="form" onSubmit={this.handleFormCreateEmailSubmit} noValidate id="create-email-form">
          <div className="form__column form__column_first">
            <div className="form__title-container">
              <div className="form__title-block">
                <h2 className="title-h2 form__title">
                  <span className="form__title-value" dangerouslySetInnerHTML={{__html: i18n.t("create_page__form_title_step_2")}} />
                </h2>
              </div>
              <p
                  className="form__subtitle"
                  dangerouslySetInnerHTML={{__html: i18n.t("create_page__form_message_step_2")}} />
            </div>
          </div>

          <div className="form__column form__column_second">
            <div className="form__column-container">
              <div className="form__column-info">
                <div className={"form__email-container " + (this.state.form.email.error ? "form__email-error" : "")}>
                  <div className="form__input-container">
                    <input
                      type="email"
                      className="form__input form__email"
                      value={this.state.form.email.value}
                      onChange={this.handleEmailFormFieldChange} />
                    <span
                      hidden={!this.state.form.email.error}
                      className="form__email-error-message"
                      dangerouslySetInnerHTML={{__html: this.state.form.email.error}} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <section className="collage-type">
          <input
              form="create-email-form"
              type="submit"
              className="btn btn_paint"
              value={i18n.t("create_page__form_submit")} />
        </section>
      </div>
    </main>;
  };

  renderStepCaption = () => {
    return <main className="main-section create-page">
      <div className="container">
        <PageHeaderView />

        <form className="caption-form" onSubmit={this.handleCaptionFormSubmit}>
          <h3 className="caption-form__title" dangerouslySetInnerHTML={{__html: i18n.t("caption_form_title")}}/>
          <div className="caption-form__container">
            <input className="caption-form__input" type="text" maxLength="250" value={this.state.collageCaption} onChange={this.handleCaptionChanged}/>
            <button className="caption-form__button btn btn_small btn_paint" dangerouslySetInnerHTML={{__html: i18n.t("caption_form_button")}}/>
          </div>
        </form>

        <button
            className="btn btn_width-big btn_paint"
            children={i18n.t("caption_form_skip")}
            onClick={this.handleSkipCaptionButtonClick} />
      </div>
    </main>;
  };

  renderStepTemplateSelect = () => {
    const creative = this.state.creatives.find((creative) => {
      return creative.alias === creativeUtils.aliases.PREVIEW;
    });

    const previews = Object.keys(creative.extra.variants).map((templateId) => {
      return {
        id: templateId,
        imageUrl: creative.extra.variants[templateId]
      };
    });

    return <main className="main-section create-page portraits">
      <div className="container">
        <PageHeaderView />

        <div className="portraits__title-container">
          <h2 className="title-h2 portraits__title"
              dangerouslySetInnerHTML={{__html: i18n.t("create_page__select_variant_title")}} />
          <FileChooseButton
            className="btn btn_width-big btn_transparent"
            children={i18n.t("try_another_photo")}
            place="CreatePage_CreativePreviewsList"
            onFileSelected={this.handleAnotherFileSelected} />
        </div>

        <div className="portraits__list">
          {previews.map((preview) => <div
            key={preview.id}
            className="portraits__item"
            onClick={() => this.handleCreativePreviewSelect(preview.id)}>
            <img className="portraits__image" src={preview.imageUrl} alt={preview.id} />
          </div>)}
        </div>
      </div>
    </main>;
  };

  renderStepConfirmation = () => {
    const creative = this.state.creatives.find((creative) => {
      return creative.alias === creativeUtils.aliases.RESULT;
    });

    const showWarning = creative.extra.real_face_rect && creative.extra.real_face_rect.w < 200;

    return <main className="main-section create-page portraits portraits_preview">
      <div className="container">
        <PageHeaderView />

        <div className="portraits__container">        
          <div className="portraits__title-container">
            <h2 className="title-h2 portraits__title"
                dangerouslySetInnerHTML={{__html: i18n.t("create_page__confirm_variant_title")}} />
            <p>{i18n.t("create_page__confirm_variant_subtitle")}</p>
          </div>
          <div>
            <div className="portraits__image-container">
              <img
                className="portraits__preview-image"
                src={creative.file.url || creative.file || ""}
                alt="Preview" />
              {showWarning && <Warning />}
            </div>

            <div className="portraits__buttons-container">
              <button
                className="btn btn_width-big btn_paint"
                children={i18n.t("create_page__confirm_variant__continue_button")}
                onClick={this.handleConfirmCreativeButtonClick} />
              <FileChooseButton
                className="btn btn_width-big btn_transparent"
                children={i18n.t("try_another_photo")}
                place="CreatePage_CreativePreview"
                onFileSelected={this.handleAnotherFileSelected} />
            </div>
          </div>
        </div>
      </div>
    </main>;
  };

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    switch (this.state.step) {
      case steps.start:
        return this.renderStepStart();
      case steps.email:
        return this.renderStepEmail();
      case steps.caption:
        return this.renderStepCaption();
      case steps.templateSelect:
        return this.renderStepTemplateSelect();
      case steps.confirmation:
        return this.renderStepConfirmation();
      default:
        return <div>Wrong state</div>;
    }
  }
}

CreatePage.contextType = AppContext;

// function EmailTooltip({isVisible, onCloseButtonClick}) {
//   return <section className="tooltip" hidden={!isVisible}>
//     <h4
//       className="tooltip__title"
//       dangerouslySetInnerHTML={{__html: i18n.t("create_page__form_email__tip_title")}} />
//     <p
//       className="tooltip__text"
//       dangerouslySetInnerHTML={{__html: i18n.t("create_page__form_email__tip_text")}} />
//     <button
//       className="tooltip__btn-close"
//       onClick={onCloseButtonClick} />
//     <div
//       className="tooltip__triangle" />
//   </section>;
// }

class EmailModal extends Modal {

  renderModal() {
    return <div className="modal__content">
      <h2 className="title-h2 modal__title"
          dangerouslySetInnerHTML={{__html: i18n.t("create_page__form_email__tip_title")}} />
      <p className="modal__text"
         dangerouslySetInnerHTML={{__html: i18n.t("create_page__form_email__tip_text")}} />
      <button
        className="btn btn_width-big btn_paint"
        children="OK"
        onClick={() => this.dismiss()} />

      <div className="bubble bubble-ex-large modal__bubble1" />
    </div>
  }
}

class Warning extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hide: false,
    };
  }

  handleCloseClick = () => {
    this.setState({
      hide: true,
    });
  };

  render() {
    if (this.state.hide) {
      return null;
    }

    return <div className="image-warning-tooltip">
      <h3 dangerouslySetInnerHTML={{__html: i18n.t("image_warning_title")}} />
      <p dangerouslySetInnerHTML={{__html: i18n.t("image_warning_text")}} />
      <button onClick={this.handleCloseClick}>
        <SvgSprite viewBox="0 0 12 12" icon="icon-close-tooltip" />
      </button>
      <div className="triangle" />
    </div>;
  }
}