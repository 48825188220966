import React from "react";
import AppContext from "../contexts/AppContext";
import Loading from "../components/Loading";
import routes from "../routes";
import * as api from "../utils/api";
import * as collageUtils from "../utils/collage";
import ErrorModal from "../components/ErrorModal";
import i18n from "../i18n";
import {ApiResponseError} from "../utils/api";
import {apiResponseErrorCodes} from "../utils/api";
import {getLocationQueryObject} from "../utils/text";
import {logEvent, userEvents} from "../utils/log";
import {webviewShareDownload} from "../utils/webview";
import PageHeaderView from "../components/PageHeaderView";
import FileChooseButton from "../components/FileChooseButton";
import {SvgSprite} from "../components/SvgSprite";

const FETCH_INTERVAL = 1000;

export default class CollageDownloadPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      collage: null,
    };

    this.fetchTimer = null;
  }

  componentDidMount() {
    this.fetchCollage();
  }

  componentWillUnmount() {
    clearTimeout(this.fetchTimer);
  }

  fetchCollage = () => {
    api.fetchCollage(this.props.match.params.hash)
      .then((res) => this.handleCollage(res.collage))
      .catch(this.handleError)
  };

  handleCollage = (collage) => {
    if (this.state.collage === null) {
      const query = getLocationQueryObject();

      logEvent(userEvents.PAGE_COLLAGE_DOWNLOAD, {
        collage_id: collage.id,
        style: collage.style,
        is_owner: collage.is_owner,
        ref: query.ref || undefined,
      });
    }

    const nextState = {collage};

    if (collageUtils.isProcessed(collage)) {
      nextState.isLoading = false;
    } else if (collageUtils.isFailed(collage)) {
      this.handleCollageError(collage);
    } else {
      this.fetchTimer = setTimeout(this.fetchCollage, FETCH_INTERVAL);
    }

    this.setState(nextState);
  };

  handleCollageError = (collage) => {
    this.context.pushModal(ErrorModal, {
      key: "CollageDownloadPage-ErrorModal",
      buttons: ErrorModal.getDefaultReloadButton(),
    });
  };

  handleError = (err) => {
    this.setState({isLoading: false});

    if (window.appConfig.isDebug) {
      console.error(err);
    }

    const props = {
      key: "CollageDownloadPage-ErrorModal",
      buttons: <React.Fragment>
        <button
          className="btn btn_width-big btn_paint"
          children={i18n.t("error_modal__reload_page")}
          onClick={() => window.location.reload()} />
      </React.Fragment>,
    };

    if (err instanceof ApiResponseError) {
      switch (err.code) {
        case apiResponseErrorCodes.NOT_FOUND: {
          props.message = i18n.t("collage_page__collage_not_found");
          break;
        }
        case apiResponseErrorCodes.DELETED: {
          props.message = i18n.t("collage_page__collage_is_deleted");
          break;
        }
        default:
          break;
      }
    }

    this.context.pushModal(ErrorModal, {...props});
  };

  handleDownloadButtonClick = () => {
    logEvent(userEvents.DOWNLOAD, {
      collage_id: this.state.collage.id,
      style: this.state.collage.style,
      is_owner: this.state.collage.is_owner,
      page: "collage_download",
    });

    if (window.clientConfig.isWebview) {
      webviewShareDownload(this.state.collage.file.url);
    } else {
      window.location.href = this.state.collage.urls.download;
    }
  };

  handleCreateNewCollageFileSelected = (file) => {
    this.props.history.push(routes.CREATE, {file});
  };

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    const collageFile = this.state.collage.is_owner
      ? (this.state.collage.caption_file || this.state.collage.file)
      : (this.state.collage.preview_caption_file || this.state.collage.preview_file || this.state.collage.file);

    return <main className="main-section attach-page attach-result download-page">
      <div className="container">
        <PageHeaderView />

        <div className="portraits__container">
          <div className="portraits__title-container">
            <h2 className="title-h2 portraits__title" dangerouslySetInnerHTML={{__html: i18n.t("collage_download_page__title")}} />
            <p dangerouslySetInnerHTML={{__html: i18n.t("collage_download_page__text")}} />
            {/* <button
              className="btn btn_width-big btn_transparent"
              children={i18n.t("btn_download")}
              onClick={this.handleDownloadButtonClick}
            /> */}
            <button
              className="btn btn_width-big btn_transparent btn_download"
              onClick={this.handleDownloadButtonClick}>
              <SvgSprite viewBox="0 0 16 16" icon="icon-download" />
              <span dangerouslySetInnerHTML={{__html: i18n.t("btn_download")}} />
            </button>
          </div>
          <img
            className="portraits__preview-image"
            src={collageFile.url}
            alt="Preview" />
        </div>
      </div>
      <div className="btns-container-desktop">
        <div className="container">
          <button
            className="btn btn_width-big btn_transparent btn_download"
            onClick={this.handleDownloadButtonClick}>
            <SvgSprite viewBox="0 0 16 16" icon="icon-download" />
            <span dangerouslySetInnerHTML={{__html: i18n.t("btn_download")}} />
          </button>
          <FileChooseButton
            className="btn btn_width-big btn_paint"
            children={i18n.t("btn_start_my_own_collage")}
            place="CollageDownloadPage"
            onFileSelected={this.handleCreateNewCollageFileSelected} />
        </div>
      </div>
    </main>;
  }
}

CollageDownloadPage.contextType = AppContext;