import React from "react";
import Modal from "./Modal";
import {SvgSprite} from "../components/SvgSprite";
import * as creativeUtils from "../utils/creative";
import * as api from "../utils/api";
import Loading from "./Loading";
import i18n from "../i18n";
import {assetUrl} from "../utils/etc";
import {logEvent, userEvents} from "../utils/log";
import ErrorModal from "./ErrorModal";
import ClientStorage from "../utils/client-storage";

const genders = {male: "male", female: "female"};
const defaultGender = genders.male;
export const bodiesTemplatesList = [
  {id: 5169, gender: genders.male},
  {id: 5098, gender: genders.male},
  {id: 5170, gender: genders.male},
  {id: 5177, gender: genders.male},
  {id: 5178, gender: genders.male},
  {id: 5179, gender: genders.female},
  {id: 5180, gender: genders.male},
  {id: 5181, gender: genders.male},
  {id: 5182, gender: genders.male},
  {id: 5183, gender: genders.male},
  {id: 5184, gender: genders.female},
  {id: 5185, gender: genders.female},
  {id: 5186, gender: genders.female},
  {id: 5187, gender: genders.female},
  // {id: 5188, gender: genders.female} // клон 5192
  {id: 5189, gender: genders.male},
  {id: 5190, gender: genders.male},
  {id: 5191, gender: genders.male},
  {id: 5192, gender: genders.female},
  {id: 5193, gender: genders.male},
  {id: 5194, gender: genders.male},
  {id: 5195, gender: genders.male},
  {id: 5199, gender: genders.male},
  // {id: 5200, gender: genders.male}, // обрубленный, не используем
  {id: 5201, gender: genders.female},
  {id: 5202, gender: genders.male},
  {id: 5203, gender: genders.male},
].shuffle();

export default class BodyChooserModal extends Modal {

  state = {
    isLoading: false,
    gender: defaultGender,
    templates: bodiesTemplatesList.filter((t) => t.gender === defaultGender),
    selectedTemplateId: null,
    creatives: [],
  };

  componentDidMount() {
    const gender = this.props.photo.gender.length !== 0
      ? this.props.photo.gender
      : defaultGender;

    logEvent(userEvents.BODYCHOOSER_OPEN, {
      gender: gender,
      style: this.props.photo.style,
    });

    const nextState = {
      isLoading: this.props.isAutomode,
      gender,
      templates: bodiesTemplatesList.filter((t) => t.gender === gender),
    };

    this.setState(nextState, () => {
      this.handleTemplateSelect(this.props.selectTemplateId || this.state.templates[0].id)
    });
  }

  componentWillUnmount() {
    clearTimeout(this.fetchTimer);
  }

  handleCancelButtonClick = () => {
    this.dismiss();
  };

  handlePhotoStatus = ({photo, creatives}) => {
    const hasBodiesIsInProcessing = creatives.has((creative) => {
      return creativeUtils.isBody(creative, this.props.sourceCreativeId)
          && creativeUtils.isProcessing(creative);
    });

    if (hasBodiesIsInProcessing) {
      clearTimeout(this.fetchTimer);
      this.fetchTimer = setTimeout(() => {
        api.fetchPhoto(photo.id)
          .then(this.handlePhotoStatus)
          .catch(this.handleError);
      }, 1000);
    }

    this.setState({creatives}, () => {
      if (this.props.isAutomode) {
        const creative = creatives.find((creative) => {
          return creativeUtils.isBody(creative, this.props.sourceCreativeId)
            && creativeUtils.isTemplateId(creative, this.state.selectedTemplateId);
        });

        if (creativeUtils.isProcessed(creative)) {
          this.handleTemplateConfirm();
        }
      }
    });
  };

  handleGenderChange = (gender) => {
    this.setState({
      gender,
      templates: bodiesTemplatesList.filter((t) => t.gender === gender),
    });
  };

  handleTemplateSelect = (templateId) => {
    logEvent(userEvents.BODY_CLICK, {
      gender: this.state.gender,
      template_id: templateId,
    });

    this.setState({selectedTemplateId: templateId});

    const creative = this.state.creatives.find((creative) => {
      return creativeUtils.isBody(creative, this.props.sourceCreativeId)
          && creativeUtils.isTemplateId(creative, templateId);
    });

    if (!creative) {
      api.submitPhotoBodyTemplate(this.props.photo.id, this.props.sourceCreativeId, templateId)
        .then(this.handlePhotoStatus)
        .catch(this.handleError);
    }
  };

  handleTemplateConfirm = () => {
    logEvent(userEvents.BODY_SELECTED, {
      gender: this.state.gender,
      template_id: this.state.selectedTemplateId,
    });

    const creative = this.state.creatives.find((creative) => {
      return creativeUtils.isBody(creative, this.props.sourceCreativeId)
          && creativeUtils.isTemplateId(creative, this.state.selectedTemplateId);
    });

    // cache for editpage
    ClientStorage.setPhotoSelectedBodyTemplateId(this.props.photo.id, this.state.selectedTemplateId);

    this.setState({isLoading: true}, () => {
      api.persistCreativeBody(creative.id)
        .then(this.handleCreativeStatus)
        .catch(this.handleError);
    });
  };

  handleCreativeStatus = (creative) => {
    if (creativeUtils.isProcessed(creative)) {
      this.props.onSelectedCreative && this.props.onSelectedCreative(creative);
      this.dismiss();
    } else if (creativeUtils.isFailed(creative)) {
      this.setState({
        isLoading: false,
        creatives: this.state.creatives.map((c) => c.id === creative.id ? creative : c),
      });
    } else {
      clearTimeout(this.fetchTimer);
      setTimeout(() => {
        api.fetchCreative(creative.id)
          .then(this.handleCreativeStatus)
          .catch(this.handleError);
      }, 1000);
    }
  };

  handleError = (err) => {
    if (window.appConfig.isDebug) {
      console.error(err);
    }

    this.context.pushModal(ErrorModal, {
      key: `${this.props.key}-ErrorModal`,
      error: err,
      buttons: ErrorModal.getDefaultReloadButton(),
    });
  };

  handleRetryClick = (creative) => {
    clearTimeout(this.fetchTimer);
    api.submitPhotoBodyTemplateRetry(this.props.photo.id, creative.id)
      .then(this.handlePhotoStatus)
      .catch(this.handleError);
  };

  renderModal() {
    if (this.state.isLoading) {
      return <Loading evenWhenModal withoutText />;
    }

    const creative = this.state.creatives.find((creative) => {
      return creativeUtils.isBody(creative, this.props.sourceCreativeId)
          && creative.template.id === this.state.selectedTemplateId;
    });

    const creativeIsProcessing = !creative || creativeUtils.isProcessing(creative);
    const creativeIsProcessed = creative && creativeUtils.isProcessed(creative);
    const creativeIsFailed = creative && creativeUtils.isFailed(creative);

    return <div className="modal__content modal__set-email choose-body">
      <div className="modal__content-container">

        <h2 className="title-h2 main-page__title"
            dangerouslySetInnerHTML={{__html: i18n.t("choose_body_title")}} />

        <div className="choose-body-btns">
          <button
            className={this.state.gender === genders.male ? "active" : ""}
            children="Male"
            onClick={() => this.handleGenderChange(genders.male)} />
          <button
            className={this.state.gender === genders.female ? "active" : ""}
            children="Female"
            onClick={() => this.handleGenderChange(genders.female)} />
        </div>

        <div className="choose-body-content">
          <div className="choose-body-templates">
            {this.state.templates.map((template) => <button
              key={template.id}
              className={template.id === this.state.selectedTemplateId ? "active" : ""}
              style={{backgroundImage: `url(${assetUrl(`assets/img/bodies/${template.id}.png`)})`}}
              onClick={() => this.handleTemplateSelect(template.id)}
            />)}
          </div>

          <div className="creative-block">
            {creativeIsProcessing && <div className="proccesing">
              <Loading evenWhenModal withoutText />
            </div>}

            {creativeIsProcessed && <div className="creative-container">
              <img className="creative" src={(creative.file && creative.file.url) || creative.file} alt="Result" />
            </div>}

            {creativeIsFailed && <ErrorView
              creative={creative}
              onRetryClick={() => this.handleRetryClick(creative)}
            />}
          </div>
        </div>

        {creativeIsProcessed && <button className="btn-select" onClick={this.handleTemplateConfirm}>
            <span children={"select"} />
            <SvgSprite viewBox="0 0 16 16" icon="icon-check-white" />
          </button>}
      </div>

      <button
        className="btn-close"
        onClick={this.handleCancelButtonClick}>
        <SvgSprite viewBox="0 0 12 12" icon="icon-close-tooltip" />
      </button>
    </div>
  }
}

function ErrorView({creative, onRetryClick}) {
  const errorMessage = (creative.error && creative.error.message)
    || i18n.t("error_modal__default_message");

  return <div className="error-container">
    <p dangerouslySetInnerHTML={{__html: errorMessage}} />
    <button dangerouslySetInnerHTML={{__html: i18n.t("btn_retry")}} onClick={onRetryClick} />
  </div>;
}