import React from 'react';
import routes from "../routes";
import AppContext from "../contexts/AppContext";
import Loading from "../components/Loading";
import i18n from "../i18n";
import * as api from "../utils/api";
import * as creativeUtils from "../utils/creative";
import * as collageUtils from "../utils/collage";
import FileChooseButton from "../components/FileChooseButton";
import CreativeErrorModal from "../components/CreativeErrorModal";
import ErrorModal from "../components/ErrorModal";
import {logEvent, userEvents} from "../utils/log";
import PageHeaderView from "../components/PageHeaderView";
import BodyChooserModal from "../components/BodyChooserModal";
import {generatePath} from "react-router";
import ClientStorage from "../utils/client-storage";

const FETCH_INTERVAL = 1000;

const steps = {
  templateSelect: "template_select",
  confirmation: "confirmation",
};

export default class EditPage extends React.Component {

  static TYPE_HEAD = "head";
  static TYPE_BODY = "body";

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      step: steps.templateSelect,
      type: EditPage.TYPE_HEAD,
      collage: null,
      photo: null,
      creatives: [],
      selectedCreativeTemplateId: null,
    };

    this.fetchTimer = null;
  }

  componentDidMount() {
    logEvent(userEvents.PAGE_EDIT);

    if ( this.props.location.state
      && this.props.location.state.collage
      && this.props.location.state.photoId
      && this.props.location.state.type
    ) {
      this.setState({
        type: this.props.location.state.type,
        collage: this.props.location.state.collage,
      }, () => {
        api.fetchPhoto(this.props.location.state.photoId)
          .then(this.handlePhotoStatus)
          .catch(this.handleError);
      });
    } else {
      this.props.history.replace(routes.INDEX);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.fetchTimer);
  }

  handlePhotoStatus = ({photo, creatives}) => {
    this.setState({
      isLoading: false,
      photo,
      creatives
    }, () => {
      if (this.state.type === EditPage.TYPE_BODY) {
        const selectedResultTemplateId = ClientStorage.getPhotoSelectedResultTemplateId(photo.id);
        this.showBodyChooserModal(creatives.find((creative) => {
          return creativeUtils.isResult(creative)
            && creativeUtils.isTemplateId(creative, selectedResultTemplateId);
        }));
      }
    });
  };

  updateCollage = (creative) => {
    api.updateCollageOwnerCreative(this.state.collage.id, creative.id)
      .then(this.handleCollageStatus)
      .catch(this.handleError);
  };

  handleCollageStatus = ({collage}) => {
    if (collageUtils.isProcessed(collage)) {
      logEvent(userEvents.COLLAGE_PROCESSED, {
        place: "EditPage",
        collage_id: collage.id,
        style: collage.style,
      });
      this.props.history.replace(generatePath(routes.FINISH, {hash: collage.hash}), {
        collage: collage,
        photo: this.state.photo,
      });
    } else if (collageUtils.isFailed(collage)) {
      logEvent(userEvents.COLLAGE_FAILED, {
        place: "EditPage",
        collage_id: collage.id,
        style: collage.style,
      });
      this.props.history.replace(generatePath(routes.COLLAGE, {hash: collage.hash}));
    } else {
      this.fetchTimer = setTimeout(() => {
        api.fetchCollage(collage.hash)
          .then(this.handleCollageStatus)
          .catch(this.handleError);
      }, FETCH_INTERVAL);
    }
  };

  showBodyChooserModal = (creative) => {
    this.context.pushModal(BodyChooserModal, {
      key: "EditPage-BodyChooserModal",
      canBeCloseByEscapeKey: true,
      photo: this.state.photo,
      sourceCreativeId: creative.id,
      isAutomode: this.state.type === EditPage.TYPE_HEAD,
      selectTemplateId: ClientStorage.getPhotoSelectedBodyTemplateId(this.state.photo.id),
      onSelectedCreative: (creative) => {
        this.setState({isLoading: true}, () => this.updateCollage(creative));
      },
    });
  };

  handleCreativePreviewSelect = (templateId) => {
    logEvent(userEvents.CREATIVE_PREVIEW_SELECT, {
      place: "EditPage",
      template_id: templateId,
      style: this.state.collage.style,
    });

    ClientStorage.setPhotoSelectedResultTemplateId(this.state.photo.id, templateId);

    this.setState({
      isLoading: true,
      selectedCreativeTemplateId: templateId,
    }, () => {
      api.submitPhotoTemplate(this.state.photo.id, templateId)
        .then(this.handleCreativeStatus)
        .catch(this.handleError);
    });
  };

  handleCreativeSelectedBodyStatus = (creative) => {
    if (creativeUtils.isProcessed(creative)) {
      this.updateCollage(creative);
    } else if (creativeUtils.isFailed(creative)) {
      this.handleCreativeError(creative);
    } else {
      clearTimeout(this.fetchTimer);
      this.fetchTimer = setTimeout(() => {
        api.fetchCreative(creative.id)
          .then(this.handleCreativeSelectedBodyStatus)
          .catch(this.handleError);
      }, FETCH_INTERVAL);
    }
  };

  handleConfirmCreativeButtonClick = () => {
    const creative = this.state.creatives.find((creative) => creativeUtils.isResult(creative));

    if (collageUtils.isBodyStyle(this.state.collage.style)) {
      this.showBodyChooserModal(creative);
    } else {
      this.setState({isLoading: true}, () => this.updateCollage(creative));
    }
  };

  handleCreativeStatus = ({photo, creatives}) => {
    const nextState = {photo, creatives};
    const creative = creatives.find((creative) => {
      return creativeUtils.isResult(creative)
          && creativeUtils.isTemplateId(creative, this.state.selectedCreativeTemplateId);
    });

    if (creativeUtils.isProcessed(creative)) {
      logEvent(userEvents.CREATIVE_PROCESSED, {
        place: "EditPage",
        type: "result",
        style: photo.style,
      });

      if (collageUtils.isBodyStyle(this.state.collage.style)) {
        nextState.isLoading = false;
        this.showBodyChooserModal(creative);
      } else {
        this.updateCollage(creative);
      }
    }
    else if (creativeUtils.isFailed(creative)) {
      logEvent(userEvents.CREATIVE_FAILED, {
        place: "EditPage",
        type: "result",
        style: photo.style,
      });

      this.handleCreativeError(creative);
    }
    else {
      this.fetchTimer = setTimeout(() => {
        api.fetchPhoto(this.state.photo.id)
          .then(this.handleCreativeStatus)
          .catch(this.handleError);
      }, FETCH_INTERVAL);
    }

    this.setState(nextState);
  };

  handleCreativeError = (creative) => {
    this.context.pushModal(CreativeErrorModal, {
      key: "EditPage-CreativeErrorModal-" + creative.id,
      creative: creative,
      onFileSelected: this.handleAnotherFileSelected,
    });
  };

  handleError = (err) => {
    this.setState({isLoading: false});

    if (window.appConfig.isDebug) {
      console.error(err);
    }

    let button = ErrorModal.getDefaultReloadButton();
    if (err.code === 415) {
      button = <FileChooseButton
        className="btn btn_width-big btn_paint"
        children={i18n.t("try_another_photo")}
        place="EditPage_ErrorModal"
        onFileSelected={(file) => {
          this.context.popModal();
          this.handleAnotherFileSelected(file);
        }} />;
    }

    this.context.pushModal(ErrorModal, {
      key: "EditPage-ErrorModal",
      error: err,
      buttons: button,
    });
  };

  renderStepTemplateSelect = () => {
    const creative = this.state.creatives.find((creative) => {
      return creative.alias === creativeUtils.aliases.PREVIEW;
    });

    const previews = Object.keys(creative.extra.variants).map((templateId) => {
      return {
        id: templateId,
        imageUrl: creative.extra.variants[templateId]
      };
    });

    return <main className="main-section create-page portraits">
      <div className="container">
        <PageHeaderView />

        <div className="portraits__title-container">
          <h2 className="title-h2 portraits__title"
              dangerouslySetInnerHTML={{__html: i18n.t("create_page__select_variant_title")}} />
        </div>

        <div className="portraits__list">
          {previews.map((preview) => <div
            key={preview.id}
            className="portraits__item"
            onClick={() => this.handleCreativePreviewSelect(preview.id)}>
            <img className="portraits__image" src={preview.imageUrl} alt={preview.id} />
          </div>)}
        </div>
      </div>
    </main>;
  };

  renderStepConfirmation = () => {
    const creative = this.state.creatives.find((creative) => {
      return creative.alias === creativeUtils.aliases.RESULT;
    });

    return <main className="main-section create-page portraits portraits_preview">
      <div className="container">
        <PageHeaderView />

        <div className="portraits__container">        
          <div className="portraits__title-container">
            <h2 className="title-h2 portraits__title"
                dangerouslySetInnerHTML={{__html: i18n.t("create_page__confirm_variant_title")}} />
            <p>{i18n.t("create_page__confirm_variant_subtitle")}</p>
          </div>
          <div>
            <div className="portraits__image-container">
              <img
                className="portraits__preview-image"
                src={creative.file.url || creative.file || ""}
                alt="Preview" />
            </div>

            <div className="portraits__buttons-container">
              <button
                className="btn btn_width-big btn_paint"
                children={i18n.t("create_page__confirm_variant__continue_button")}
                onClick={this.handleConfirmCreativeButtonClick} />
              <FileChooseButton
                className="btn btn_width-big btn_transparent"
                children={i18n.t("try_another_photo")}
                place="EditPage_CreativePreview"
                onFileSelected={this.handleAnotherFileSelected} />
            </div>
          </div>
        </div>
      </div>
    </main>;
  };

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    switch (this.state.step) {
      case steps.templateSelect:
        return this.renderStepTemplateSelect();
      case steps.confirmation:
        return this.renderStepConfirmation();
      default:
        return <div>Wrong state</div>;
    }
  }
}

EditPage.contextType = AppContext;