import React from 'react';
import FileChooseButton from "../components/FileChooseButton";
import routes from "../routes";
import AppContext from "../contexts/AppContext";
import {logEvent, userEvents} from "../utils/log";
import i18n from "../i18n";
import {assetUrl, buildScreenSizeImagesPath, buildScreenSizeImagesSrcString} from "../utils/etc";
import PageHeaderView from "../components/PageHeaderView";
import Slider from "react-slick";

export default class IndexPage extends React.Component {

  constructor(props) {
    super(props);
    this.handleFileSelected = this.handleFileSelected.bind(this);
  }

  componentDidMount() {
    logEvent(userEvents.PAGE_INDEX);

    document.title = i18n.t("title");
  }

  handleFileSelected(file) {
    this.props.history.push(routes.CREATE, {file});
  }

  render() {
    return <PageViewWithSlider
      handleFileSelected={this.handleFileSelected}
    />;
  }
}

IndexPage.contextType = AppContext;

// eslint-disable-next-line no-unused-vars
function PageViewWithVideo({handleFileSelected}) {
  return <main className="main-section main-page main-page__video-view">
    <div className="container">
      <div className="main-page__video-view-container">
        <PageHeaderView logotypeIsDisabled showContactUsLink={window.clientConfig.isWeb} />

        <video className="video-background" autoPlay loop muted playsInline>
          <source src={assetUrl("assets/img/unite.com_2.mp4")} type="video/mp4" />
        </video>

        <FileChooseButton
          className="main-page__btn btn btn_height-big btn_width-big btn_paint"
          children={i18n.t("index_page__select_photo_button")}
          place="IndexPage"
          onFileSelected={handleFileSelected} />
      </div>

      <div>
        <h2 className="title-h2 main-page__title"
            dangerouslySetInnerHTML={{__html: i18n.t("index_page__greeting")}} />
        <p className="main-page__text" dangerouslySetInnerHTML={{__html: i18n.t("index_page__text")}} />
      </div>
    </div>
  </main>;
}

// eslint-disable-next-line no-unused-vars
function PageViewDefault({handleFileSelected}) {
  const backgroundWebpMobEx = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles_mob_ex.webp"), ["1x", "2x", "3x"]);
  const backgroundPngMobEx = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles_mob_ex.png"), ["1x", "2x", "3x"]);
  const backgroundWebpMob = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles_mob.webp"), ["1x", "2x", "3x"]);
  const backgroundPngMob = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles_mob.png"), ["1x", "2x", "3x"]);
  const backgroundWebp = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles.webp"), ["1x", "2x", "3x"]);
  const backgroundPng = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles.png"), ["1x", "2x", "3x"]);

  return <main className="main-section main-page">
    <div className="container">
      <PageHeaderView logotypeIsDisabled showContactUsLink={window.clientConfig.isWeb} />
      <div className="main-page__content">
        <h2 className="title-h2 main-page__title"
            dangerouslySetInnerHTML={{__html: i18n.t("index_page__greeting")}} />
        <p className="main-page__text" dangerouslySetInnerHTML={{__html: i18n.t("index_page__text")}} />
        <FileChooseButton
            className="main-page__btn btn btn_height-big btn_width-big btn_paint"
            children={i18n.t("index_page__select_photo_button")}
            place="IndexPage"
            onFileSelected={handleFileSelected} />
      </div>
      <div className="bubble bubble-ex-large main-page__bubble1" />
      <div className="bubble bubble-small main-page__bubble2" />
      <div className="bubble bubble-large main-page__bubble3" />
      <div className="bubble bubble-small main-page__bubble4" />
      <div className="bubble bubble-medium main-page__bubble5" />
    </div>
    <picture tabIndex="-1">
      <source srcSet={buildScreenSizeImagesSrcString(backgroundWebpMobEx)} media="(max-height: 800px) and (max-width: 430px)" type="image/webp" />
      <source srcSet={buildScreenSizeImagesSrcString(backgroundPngMobEx)} media="(max-height: 800px) and (max-width: 430px)" />
      <source srcSet={buildScreenSizeImagesSrcString(backgroundWebpMob)} media="(max-width: 720px)" type="image/webp" />
      <source srcSet={buildScreenSizeImagesSrcString(backgroundPngMob)} media="(max-width: 720px)" />
      <source srcSet={buildScreenSizeImagesSrcString(backgroundWebp)} type="image/webp" />
      <img
          className="main-page__bg"
          srcSet={buildScreenSizeImagesSrcString(backgroundPng)}
          src={backgroundPng.base}
          alt="Background" />
    </picture>
  </main>;
}

function PageViewWithSlider({handleFileSelected}) {
  const sliderSettings = {
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    infinite: true,
    responsive: [{
      breakpoint: 450,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '60px',
      }
    }, {
      breakpoint: 360,
      settings: {
        centerPadding: '50px',
      }
    }],
  };

  const indexImagesSplitGroups = {
    "default": [5, 1, 2, 3, 5, 1, 2, 3,],
  };

  const indexImages = indexImagesSplitGroups["default"];

  const containerClasses = ["main-section", "main-page", "main-page-slider"];
  if (window.clientConfig.splitsGroupId < 6) {
    containerClasses.push("main-button-below-text");
  }

  return <main className={containerClasses.join(" ")}>
    <div className="container">
      <PageHeaderView logotypeIsDisabled showContactUsLink={window.clientConfig.isWeb} />
      <div className="main-page__container">
        <div className="main-page__content">
          <h2 className="title-h2 main-page__title"
              dangerouslySetInnerHTML={{__html: i18n.t("index_page__greeting")}} />
          <p className="main-page__text" dangerouslySetInnerHTML={{__html: i18n.t("index_page__text")}} />
          <FileChooseButton
              className="main-page__btn btn btn_height-big btn_width-big btn_paint"
              children={i18n.t("index_page__select_photo_button")}
              place="IndexPage"
              onFileSelected={handleFileSelected} />
        </div>

        <Slider {...sliderSettings} className="main-slider">
          {indexImages.map((number) => <SlideView key={number} number={number} />)}
        </Slider>
      </div>
      <div className="bubble bubble-ex-large main-page__bubble1" />
      <div className="bubble bubble-small main-page__bubble2" />
      <div className="bubble bubble-large main-page__bubble3" />
      <div className="bubble bubble-small main-page__bubble4" />
      <div className="bubble bubble-medium main-page__bubble5" />
    </div>
  </main>;
}

// eslint-disable-next-line no-unused-vars
function PageViewV2({handleFileSelected}) {
  const backgroundWebpMob = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles_mob2.webp"), ["1x", "2x", "3x"]);
  const backgroundPngMob = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles_mob2.png"), ["1x", "2x", "3x"]);
  const backgroundWebp = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles2.webp"), ["1x", "2x", "3x"]);
  const backgroundPng = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles2.png"), ["1x", "2x", "3x"]);

  return <main className="main-section main-page main-page2">
    <div className="container">
      <PageHeaderView logotypeIsDisabled showContactUsLink={window.clientConfig.isWeb} />
      <div className="main-page__content-container">
        <div className="main-page__content">
          <h2 className="title-h2 main-page__title"
              dangerouslySetInnerHTML={{__html: i18n.t("index_page__greeting")}} />
          <p className="main-page__text" dangerouslySetInnerHTML={{__html: i18n.t("index_page__text")}} />
          <FileChooseButton
              className="main-page__btn btn btn_height-big btn_width-big btn_paint"
              children={i18n.t("index_page__select_photo_button")}
              place="IndexPage"
              onFileSelected={handleFileSelected} />
        </div>
        <div className="bubble bubble-ex-large main-page__bubble1" />
        <div className="bubble bubble-small main-page__bubble2" />
        <div className="bubble bubble-large main-page__bubble3" />
        <div className="bubble bubble-small main-page__bubble4" />
        <div className="bubble bubble-medium main-page__bubble5" />
        <picture tabIndex="-1">
          <source srcSet={buildScreenSizeImagesSrcString(backgroundWebpMob)} media="(max-width: 991px)" type="image/webp" />
          <source srcSet={buildScreenSizeImagesSrcString(backgroundPngMob)} media="(max-width: 991px)" />
          <source srcSet={buildScreenSizeImagesSrcString(backgroundWebp)} type="image/webp" />
          <img
              className="main-page__bg"
              srcSet={buildScreenSizeImagesSrcString(backgroundPng)}
              src={backgroundPng.base}
              alt="Background" />
        </picture>
      </div>
    </div>
  </main>;
}

// eslint-disable-next-line no-unused-vars
function PageViewV3({handleFileSelected}) {
  const backgroundWebpMob = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles_mob3.webp"), ["1x", "2x", "3x"]);
  const backgroundPngMob = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles_mob3.png"), ["1x", "2x", "3x"]);
  const backgroundWebp = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles3.webp"), ["1x", "2x", "3x"]);
  const backgroundPng = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles3.png"), ["1x", "2x", "3x"]);

  return <main className="main-section main-page main-page2 main-page3">
    <div className="container">
      <PageHeaderView logotypeIsDisabled showContactUsLink={window.clientConfig.isWeb} />
      <div className="main-page__content-container">
        <div className="main-page__content">
          <h2 className="title-h2 main-page__title"
              dangerouslySetInnerHTML={{__html: i18n.t("index_page__greeting")}} />
          <p className="main-page__text" dangerouslySetInnerHTML={{__html: i18n.t("index_page__text")}} />
          <FileChooseButton
              className="main-page__btn btn btn_height-big btn_width-big btn_paint"
              children={i18n.t("index_page__select_photo_button")}
              place="IndexPage"
              onFileSelected={handleFileSelected} />
        </div>
        <div className="bubble bubble-ex-large main-page__bubble1" />
        <div className="bubble bubble-small main-page__bubble2" />
        <div className="bubble bubble-large main-page__bubble3" />
        <div className="bubble bubble-small main-page__bubble4" />
        <div className="bubble bubble-medium main-page__bubble5" />
        <picture tabIndex="-1">
          <source srcSet={buildScreenSizeImagesSrcString(backgroundWebpMob)} media="(max-width: 991px)" type="image/webp" />
          <source srcSet={buildScreenSizeImagesSrcString(backgroundPngMob)} media="(max-width: 991px)" />
          <source srcSet={buildScreenSizeImagesSrcString(backgroundWebp)} type="image/webp" />
          <img
              className="main-page__bg"
              srcSet={buildScreenSizeImagesSrcString(backgroundPng)}
              src={backgroundPng.base}
              alt="Background" />
        </picture>
      </div>
    </div>
  </main>;
}

// eslint-disable-next-line no-unused-vars
function PageViewV4({handleFileSelected}) {
  const backgroundWebpMob = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles_mob4.webp"), ["1x", "2x", "3x"]);
  const backgroundPngMob = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles_mob4.png"), ["1x", "2x", "3x"]);
  const backgroundWebp = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles2.webp"), ["1x", "2x", "3x"]);
  const backgroundPng = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles2.png"), ["1x", "2x", "3x"]);

  return <main className="main-section main-page main-page2 main-page4">
    <div className="container">
      <PageHeaderView logotypeIsDisabled showContactUsLink={window.clientConfig.isWeb} />
      <div className="main-page__content-container">
        <div className="main-page__content">
          <h2 className="title-h2 main-page__title"
              dangerouslySetInnerHTML={{__html: i18n.t("index_page__greeting")}} />
          <p className="main-page__text" dangerouslySetInnerHTML={{__html: i18n.t("index_page__text")}} />
          <FileChooseButton
              className="main-page__btn btn btn_height-big btn_width-big btn_paint"
              children={i18n.t("index_page__select_photo_button")}
              place="IndexPage"
              onFileSelected={handleFileSelected} />
        </div>
        <div className="bubble bubble-ex-large main-page__bubble1" />
        <div className="bubble bubble-small main-page__bubble2" />
        <div className="bubble bubble-large main-page__bubble3" />
        <div className="bubble bubble-small main-page__bubble4" />
        <div className="bubble bubble-medium main-page__bubble5" />
        <picture tabIndex="-1">
          <source srcSet={buildScreenSizeImagesSrcString(backgroundWebpMob)} media="(max-width: 991px)" type="image/webp" />
          <source srcSet={buildScreenSizeImagesSrcString(backgroundPngMob)} media="(max-width: 991px)" />
          <source srcSet={buildScreenSizeImagesSrcString(backgroundWebp)} type="image/webp" />
          <img
              className="main-page__bg"
              srcSet={buildScreenSizeImagesSrcString(backgroundPng)}
              src={backgroundPng.base}
              alt="Background" />
        </picture>
      </div>
    </div>
  </main>;
}

export function SlideView({number}) {
  const jpg = [
    assetUrl(`assets/img/collages-main-slider/collage_${number}.jpg`) + " 1x",
    assetUrl(`assets/img/collages-main-slider/collage_${number}@2x.jpg`) + " 2x",
    assetUrl(`assets/img/collages-main-slider/collage_${number}@3x.jpg`) + " 3x"
  ].join(", ");

  const webp = [
    assetUrl(`assets/img/collages-main-slider/collage_${number}.webp`) + " 1x",
    assetUrl(`assets/img/collages-main-slider/collage_${number}@2x.webp`) + " 2x",
    assetUrl(`assets/img/collages-main-slider/collage_${number}@3x.webp`) + " 3x"
  ].join(", ");

  return <div className="slide-container">
    <div className="slide">
      <picture>
        <source srcSet={webp} type="image/webp" />
        <img srcSet={jpg} alt="Demo" />
      </picture>
    </div>
  </div>;
}