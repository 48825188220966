import routes from "../routes";
import i18n from "../i18n";
import {NavLink} from "react-router-dom";
import React from "react";

export default function LogotypeLink({className, disabled}) {
  className = "logo " + (className || "");

  if (disabled) {
    return <span className={className}>{i18n.t("logotype")}</span>
  }

  return <NavLink to={routes.INDEX} className={className}>{i18n.t("logotype")}</NavLink>;
}