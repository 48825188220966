import * as amplitude from "amplitude-js";
import * as api from "./api";

let webviewParamsIsCommited = false;

const userProperties = {
  client_type: window.clientConfig.isWebview ? "webview" : (window.clientConfig.isWebMobile ? "mobile" : "desktop"),
  is_webview: window.clientConfig.isWebview,
  is_mobile: window.clientConfig.isWebMobile,
  platform_browser_name: window.clientConfig.platform.name,
  platform_browser_version: window.clientConfig.platform.version,
  platform_os: window.clientConfig.platform.os,
  screen_w: window.screen.width,
  screen_h: window.screen.height,
  viewport_w: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
  viewport_h: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
  locale: window.clientConfig.locale,
  is_pro: window.clientConfig.isPro,
  split_group: window.clientConfig.splitsGroupId,
  ref: window.clientConfig.query["ref"] || undefined,
  referrer: document.referrer || undefined,
};

if (window.clientConfig.isWebview) {
  const osName = window.clientConfig.isWebviewAndroid
    ? "Android"
    : "iOS";

  userProperties.os_version = `${osName} ${window.clientConfig.webviewParams.os_version}`;
  userProperties.native_app_build = `${osName} ${window.clientConfig.webviewParams.version_code}`;
}

Object.keys(window.clientConfig.query).forEach((key) => {
  if (key.indexOf("utm_") === 0) {
    userProperties[key] = window.clientConfig.query[key];
  }
});

const userPropertiesCommitWaited = Object.keys(userProperties);

if (window.appConfig.isDebug) {
  console.log("initial user properties", userProperties);
}

if (window.appConfig.amplitude.isEnabled) {
  amplitude.getInstance().init(window.appConfig.amplitude.key);
  amplitude.getInstance().setUserProperties({...userProperties});
}

// --

export const userEvents = {
  // юзер перешёл в таб в вебвью
  // os - операционная система юзера
  WEBVIEW_TAB_FOCUS: "webview_tab_focus",

  // визит на главную
  PAGE_INDEX: "page_index",

  // визит на форму создания коллажа (после выбора фото)
  PAGE_CREATE: "page_create",

  // визит на страницу изменения тела/головы
  PAGE_EDIT: "page_edit",

  // визит на форму аттача фото к коллажу (после выбора фото)
  // collage_id - наш идентификатор коллажа
  // style - стиль коллажа
  PAGE_ATTACH: "page_attach",

  // визит на страницу коллажа
  // collage_id - наш идентификатор коллажа
  // style - стиль коллажа
  // is_owner - автор или гость
  // ref - откуда пришёл юзер
  PAGE_COLLAGE: "page_collage",
  PAGE_COLLAGE_DOWNLOAD: "page_collage_download",

  // визит на страницу управления коллажом
  // collage_id - наш идентификатор коллажа
  // style - стиль коллажа
  PAGE_MANAGE: "page_manage",

  // визит на транзитную страницу для снепчат вебвью
  // collage_hash - наш хеш коллажа
  PAGE_SNAPCHAT: "page_snapchat",

  // юзер выбрал фото
  // place - расположение кнопки выбора
  PHOTO_SELECT: "photo_select",

  // юзер успешно загрузил фото
  // place - страница
  // style - стиль коллажа
  PHOTO_UPLOADED: "photo_uploaded",

  // юзер неуспешно загрузил фото
  // place - страница
  // style - стиль коллажа
  PHOTO_UPLOAD_FAILED: "photo_upload_failed",

  // юзер выбрал превью из предоставленных (вектор)
  // place - страница (CreatePage, EditPage, AttachPage)
  // template_id - идентификатор фотолаб шаблона
  // style - стиль коллажа
  CREATIVE_PREVIEW_SELECT: "creative_preview_select",

  // успешно сгенерили обработку
  // place - страница (CreatePage, EditPage, AttachPage)
  // type - тип обработки (preview, result)
  // style - стиль коллажа
  CREATIVE_PROCESSED: "creative_processed",

  // неуспешно сгенерили обработку
  // place - страница (CreatePage, EditPage, AttachPage)
  // type - тип обработки (preview, result)
  // style - стиль коллажа
  CREATIVE_FAILED: "creative_failed",

  // успешно сгенерировали коллаж
  // place - страница (CreatePage, EditPage)
  // collage_id - наш идентификатор коллажа
  // style - стиль коллажа
  COLLAGE_PROCESSED: "collage_processed",

  // неуспешно сгенерировали коллаж
  // place - страница (CreatePage, EditPage)
  // collage_id - наш идентификатор коллажа
  // style - стиль коллажа
  COLLAGE_FAILED: "collage_failed",

  // юзер подтвердил аттач своей обработки на коллаж
  // collage_id - наш идентификатор коллажа
  // style - стиль коллажа
  COLLAGE_PHOTO_ATTACH: "collage_photo_attach",

  // автор финализировал коллаж
  // collage_id - наш идентификатор коллажа
  // style - стиль коллажа
  COLLAGE_FINALIZE: "collage_finalize",

  // автор изменил подпись для коллажа
  // collage_id - наш идентификатор коллажа
  // caption - текст подписи
  // style - стиль коллажа
  COLLAGE_CAPTION_UPDATE: "collage_caption_update",

  // автор коллажа открыл модалку Invite People
  // collage_id - наш идентификатор коллажа
  // style - стиль коллажа
  INVITE_PEOPLE_MODAL_OPEN: "invite_people_modal_open",

  // автор коллажа оставил свой email в модалке Invite People
  // collage_id - наш идентификатор коллажа
  // style - стиль коллажа
  INVITE_PEOPLE_MODAL_SUBMITTED: "invite_people_modal_submitted",

  // автор коллажа закрыл модалку Invite People не оставив свой email
  // collage_id - наш идентификатор коллажа
  // style - стиль коллажа
  INVITE_PEOPLE_MODAL_CANCELED: "invite_people_modal_canceled",

  // шаринг страницы коллажа
  // collage_id - наш идентификатор коллажа
  // style - стиль коллажа
  // provider - канал шаринга
  // page - страница с которой был шаринг
  SHARE: "share",

  // скачивание коллажа
  // collage_id - наш идентификатор коллажа
  // is_owner - скачал автор или гость
  // page - страница на которой было скачано
  DOWNLOAD: "download",

  // отправка формы выбора стиля при создании нового коллажа
  // style - стиль коллажа
  FORM_CREATE_SUBMIT: "form_create_submit",

  // DEPRECATED
  // отправка формы с вводом email (последний шаг в создании коллажа)
  // style - стиль коллажа
  FORM_CREATE_EMAIL_SUBMIT: "form_create_email_submit",

  // открытие окна выбора тела
  // gender - какой пол выбран при открытии (male/female)
  // style - стиль коллажа
  BODYCHOOSER_OPEN: "bodychooser_open",

  // юзер кликнул на тело
  // gender - male/female
  // template_id - идентификатор шаблона тела
  BODY_CLICK: "body_click",

  // юзер выбрал тело для коллажа
  // gender - male/female
  // template_id - идентификатор шаблона тела
  BODY_SELECTED: "body_selected",
};

export function logEvent(eventId, eventParams, cb) {
  if (window.appConfig.isDebug) {
    console.info("logEvent", eventId, eventParams);
  }

  if (window.appConfig.amplitude.isEnabled) {
    amplitude.getInstance().logEvent(eventId, eventParams || {}, cb);
  }

  if (window.appConfig.analytics.isEnabled) {
    let userParams = undefined;
    if (userPropertiesCommitWaited.length > 0) {
      userParams = {};
      userPropertiesCommitWaited.forEach((key) => userParams[key] = userProperties[key]);
      userPropertiesCommitWaited.length = 0;
    }

    let webviewParams = undefined;
    if (window.clientConfig.isWebview && !webviewParamsIsCommited) {
      webviewParamsIsCommited = true;
      webviewParams = window.clientConfig.webviewParams;
    }

    api.logEvent(eventId, eventParams, userParams, webviewParams)
      .catch(console.error);
  }
}

export function setUserProperty(key, value) {
  if (window.appConfig.isDebug) {
    console.log("setUserProperty", key, value);
  }

  userProperties[key] = value;
  userPropertiesCommitWaited.push(key);

  if (window.appConfig.amplitude.isEnabled) {
    amplitude.getInstance().identify(new amplitude.Identify().set(key, value));
  }
}