import {getLocationQueryObject} from "./utils/text";
import {detect} from "detect-browser";
import clientStorage from "./utils/client-storage";

function detectLocale() {
  const langs = window.navigator.languages || [window.navigator.language] || ["en"];
  const chineseLike = langs.find((locale) => locale.toLowerCase().startsWith("zh"));
  return chineseLike || langs[0];
}

const query = getLocationQueryObject();
const platformInfo = detect();
const platformIsMobile = ["android os", "ios"].indexOf(platformInfo.os.toLowerCase()) > -1;
const isWebview = typeof query["aid"] !== "undefined" || typeof query["vicman_unified_id"] !== "undefined";

window.clientConfig = {
  query: query,
  platform: detect(),
  isSnapchatWebview: window.navigator.userAgent.indexOf("Snapchat") > -1,
  isWeb: !isWebview,
  isWebMobile: !isWebview && platformIsMobile,
  isWebMobileAndroid: platformInfo.os.toLowerCase() === "android os",
  isWebMobileIOS: platformInfo.os.toLowerCase() === "ios",
  isWebDesktop: !isWebview && !platformIsMobile,
  isWebview: isWebview,
  isWebviewAndroid: typeof query["aid"] !== "undefined",
  isWebviewIOS: typeof query["vicman_unified_id"] !== "undefined",
  webviewParams: isWebview ? query : {},
  token: query["aid"] || query["vicman_unified_id"] || window.localStorage.getItem("client_token") || undefined,
  locale: query["locale"] || query["lang"] || detectLocale() || "en",
  splitsGroupId: undefined,
};

window.clientConfig.lang = window.clientConfig.locale
  ? window.clientConfig.locale.substring(0, 2).toLowerCase()
  : undefined;

if (typeof window.clientConfig.token !== "undefined") {
  window.axios.defaults.headers.common["X-Client-Token"] = window.clientConfig.token;
}

// --

if (window.clientConfig.isWebview) {
  const aid = query["aid"] || query["vicman_unified_id"];
  const num = parseInt(aid.substring(aid.length - 2), 16);
  const group = Math.floor(num / (255 / 10)) + 1;

  window.clientConfig.splitsGroupId = Math.min(group, 10); // fix for ff => 11 group
} else {
  const groupId = query["split_group"] || clientStorage.getSplitGroupId();
  if (groupId) {
    window.clientConfig.splitsGroupId = parseInt(groupId);
  } else {
    window.clientConfig.splitsGroupId = Math.floor(Math.random() * 10) + 1;
  }

  clientStorage.setSplitGroupId(window.clientConfig.splitsGroupId);
}

// --

window.appConfig = {
  isDebug: process.env.NODE_ENV.startsWith("prod") === false
    || process.env.REACT_APP_DEBUG === "true"
    || query["vicman_debug"]
    || false,

  paths: {
    app: process.env.REACT_APP_APP_PATH,
    assets: process.env.REACT_APP_ASSETS_PATH,
    api: process.env.REACT_APP_API_PATH,
    apiUpload: process.env.REACT_APP_API_FILES_PATH,
  },

  amplitude: {
    isEnabled: process.env.REACT_APP_AMPLITUDE_ENABLED === "true",
    key: process.env.REACT_APP_AMPLITUDE_KEY,
  },

  analytics: {
    isEnabled: process.env.REACT_APP_ANALYTICS_ENABLED === "true",
    endpoint: process.env.REACT_APP_ANALYTICS_ENDPOINT,
  },

  hits: {
    isEnabled: process.env.REACT_APP_HITS_ENABLED === "true",
    endpoint: process.env.REACT_APP_HITS_ENDPOINT,
  },

  sentry: {
    isEnabled: process.env.REACT_APP_SENTRY_ENABLED === "true",
    dsn: process.env.REACT_APP_SENTRY_DSN || "",
    sampleRate: 0.5,
  },

  facebook: {
    appId: 140586622674265, // unite.com old
  }
};