import Modal from "./Modal";
import {SvgSprite} from "./SvgSprite";
import i18n from "../i18n";
import React from "react";
import {ApiResponseError} from "../utils/api";

export default class ErrorModal extends Modal {

  static getDefaultReloadButton() {
    return <button
      className="btn btn_width-big btn_paint"
      children={i18n.t("error_modal__reload_page")}
      onClick={() => window.location.reload()} />;
  }

  renderModal() {
    let title = this.props.title;
    let message;

    if (this.props.message) {
      title = i18n.t("error_modal__default_title");
      message = this.props.message;
    } else if (this.props.error && this.props.error instanceof ApiResponseError) {
      title = i18n.t("error_modal__default_title");
      message = i18n.t("error__api_code_" + this.props.error.code);
    } else if (this.props.error && this.props.error.name === "NetworkError") {
      title = title || i18n.t("error_modal__network_title");
      message = i18n.t("error_modal__network_message");
    } else {
      title = i18n.t("error_modal__default_title");
      message = i18n.t("error_modal__default_message");
    }

    return <div className="modal__content">
      <SvgSprite className="modal__image modal__image_desktop" viewBox="0 0 594 262" icon="error-image" />
      <SvgSprite className="modal__image modal__image_mob" viewBox="0 0 366 256" icon="error-image-mob" />
      <h2 className="title-h2 modal__title" dangerouslySetInnerHTML={{__html: title}} />
      <p className="modal__text" dangerouslySetInnerHTML={{__html: message}} />
      {this.props.buttons}
      <div className="bubble bubble-ex-large modal__bubble1" />
    </div>
  }
}