export function utf8ToBase64(str) {
  str = (str + '');

  return window.btoa(urlencode(str));
}

export function urlencode(str) {
  str = (str + '');

  return encodeURIComponent(str)
    .replace(/!/g, '%21')
    .replace(/'/g, '%27')
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29')
    .replace(/\*/g, '%2A')
    .replace(/~/g, '%7E')
    .replace(/%20/g, '+');
}

export function getLocationQueryObject() {
  return parseQuery(window.location.search.substring(1));
}

export function parseQuery(str) {
  const res = {};

  str.split("&").forEach((param) => {
    const data = param.split("=");
    res[decodeURIComponent(data[0])] = decodeURIComponent(data[1]);
  });

  return res;
}

export function copyTextFromElement(element) {
  element.select();
  element.setSelectionRange(0, 99999);

  try {
    document.execCommand("copy");
  } catch (e) {
    return false;
  }

  return true;
}

export function copyTextWithInvisibleElement(text) {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);

  if (window.navigator.userAgent.match(/ipad|iphone/i)) {
    const range = document.createRange();
    range.selectNodeContents(textArea);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    textArea.setSelectionRange(0, 999999);
  } else {
    textArea.focus();
    textArea.select();
    textArea.setSelectionRange(0, 99999);
  }

  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Oops, unable to copy", err);
    return false;
  }

  document.body.removeChild(textArea);
  return true;
}

export function copyText(text) {
  if (window.navigator.clipboard && window.navigator.clipboard.writeText) {
    return window.navigator.clipboard.writeText(text);
  } else {
    return Promise.reject(null)
  }
}

export function buildClassNamesString(obj) {
  return Object.keys(obj).filter(key => obj[key]).join(" ");
}

export function emailIsValid(value) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
}