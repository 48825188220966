import {assetUrl} from "./etc";
import i18n from "../i18n";

export const styles = {
  vector: "vector",
  vectorBody: "vector_body",
  cartoon: "cartoon",
  cartoonVectorBody: "cartoon_vector_body",
  toonVectorBody: "toon_vector_body",
};

export const statuses = {
  failed: -1,
  processing: 0,
  processed: 1,
};

export function isProcessed(collage) {
  return collage.status === statuses.processed;
}

export function isFailed(collage) {
  return collage.status === statuses.failed;
}

export function isProcessing(collage) {
  return collage.status === statuses.processing;
}

export function isBodyStyle(style) {
  return /_body$/.test(style);
}

export function withCelebsStyle(style) {
  return [
    styles.vector,
    styles.vectorBody,
    styles.cartoon,
    styles.cartoonVectorBody,
  ].indexOf(style) > -1;
}

export function mapBonuses(collage, tooltipComponent) {
  const placeholders = [{
    file: {url: assetUrl("assets/img/collages-bonuses/disney_collage.jpg?1")},
    style: "disney_3d",
    status: null,
    text: i18n.t("manage_page__bonus_disney_3d", {count: (4 - collage.attached_items)}),
    tooltip: collage.bonuses.length === 0 ? tooltipComponent : null,
  }, {
    file: {url: assetUrl("assets/img/collages-bonuses/caricature_collage.jpg?1")},
    style: "caricature_tile",
    status: null,
    text: i18n.t("manage_page__bonus_caricature", {count: (9 - collage.attached_items)}),
  }];

  return placeholders.map((placeholder) => {
    return collage.bonuses.find((item) => item.style === placeholder.style)
      || placeholder;
  });
}